#toast-container > div {
    width: 500px;
}
.toast {
    opacity: 0.8;
    background-color: #df3388;
    max-width: 500px;
}
.toast-message {
    font-size: 25px;
    padding: 10px 40px;
}