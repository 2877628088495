/* variables */

// $black: #000;

@mixin fs($px) {
	font-size: ($px / 16) * 1rem;
}

@mixin mt($px) {
	margin-top: ($px / 16) * 1rem;
}

@mixin mb($px) {
	margin-bottom: ($px / 16) * 1rem;
}

@mixin pt($px) {
	padding-top: ($px / 16) * 1rem;
}

@mixin pb($px) {
	padding-bottom: ($px / 16) * 1rem;
}

$pink: #DF3388;
$l-violet: #963E8B;
$violet: #7C0E6F;
$green: #91B508;
$l-green: #C8DA83;
$gray: #707070;
$l-gray: #A5A1A1;

.color-pink {
	color: $pink  !important;
}

.color-gray {
	color: #A5A1A1 !important;
}

.color-violet {
	color: $violet  !important;
}

.color-l-violet {
	color: $l-violet  !important;
}

.color-white {
	color: #fff !important;
}

.color-black {
	color: #000 !important;
}

.color-green {
	color: #91B508 !important;
}

.bg-l-gray {
	background-color: #E5E5E5;
}

.bg-gray {
	background-color: #CBCBCB;
}

.bg-l-gray {
	background-color: #EFEDED;
}

.bg-white {
	background-color: #fff;
}

.bg-pink {
	background-color: $pink;
}

.bg-violet {
	background-color: $violet;
}

.bg-l-violet {
	background-color: $l-violet;
}

.bg-empty {
	background-color: transparent !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.mt-auto {
	margin-top: auto !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-15 {
	margin-top: 15px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.ml-auto {
	margin-left: auto;
}

.pb-30 {
	padding-bottom: 30px;
}

.expand-block {
	position: relative;
	margin-bottom: 10px;

	&-title,
	&-content {
		padding: 15px 15px 25px 15px;
		color: #fff;
		background: linear-gradient(to bottom, #F820EB, #900B4C);
		border-radius: 20px;
	}

	&-title {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		@include fs(26);
		transition: box-shadow .3s;

		&.a:hover {
			box-shadow: 0 0 5px #000;
		}

		&:after {
			position: absolute;
			content: "";
			left: 50%;
			transform: translateX(-50%);
			bottom: 5px;
			background-image: url(../images/icons/icon-arrow-down.svg);
			background-size: contain;
			background-repeat: no-repeat;
			width: 24px;
			height: 14px;
			transition: transform .3s;
		}

		&.bg-violet {
			background: linear-gradient(to bottom, #931C82, #4A0E41);
		}

		&.bg-l-violet {
			background: linear-gradient(to bottom, #C466BE, #701A44);
		}

		&.bg-blue {
			background: linear-gradient(to bottom, #9489C5, #4A4563);
		}

		&.bg-d-pink {
			background: linear-gradient(to bottom, #D23080, #691840);
		}
	}

	&-content {
		position: absolute;
		top: 105%;
		left: 0;
		opacity: 0;
		visibility: hidden;
		box-shadow: 0 0 5px #000;
		z-index: 10;
		transition: opacity .3s, visibility .3s;

		&.bg-violet {
			background: linear-gradient(to bottom, #931C82, #4A0E41);
		}

		&.bg-l-violet {
			background: linear-gradient(to bottom, #C466BE, #701A44);
		}

		&.bg-blue {
			background: linear-gradient(to bottom, #9489C5, #4A4563);
		}

		&.bg-d-pink {
			background: linear-gradient(to bottom, #D23080, #691840);
		}
	}

	&.active &-title:after {
		transform: translateX(-50%) rotate(180deg);
	}

	&.active &-content {
		opacity: 1;
		visibility: visible;
	}

	&.bg-empty {
		height: 100%;

		.expand-block-title {
			height: 100%;
			background: transparent;
			color: $violet;
			border: 1px solid $pink;
			text-transform: none;

			&:after {
				filter: brightness(0) saturate(100%) invert(15%) sepia(37%) saturate(6328%) hue-rotate(292deg) brightness(82%) contrast(103%);
			}
		}
	}
}

.title {
	&-mega {
		@include fs(55);
	}

	&-large {
		@include fs(42);
	}

	&-big {
		@include fs(38);
	}

	&-middle {
		@include fs(32);
	}

	&-small {
		@include fs(28);
	}

	&-w-border {
		padding-bottom: 5px;
		border-bottom: .5px solid $gray;
		margin-bottom: 30px;
	}

	&-w-icon {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 20px;
		color: $l-violet;
		font-weight: bold;

		img {
			display: inline-block;
			margin-right: 15px;
		}

		.btn {
			margin-left: auto;
			margin-top: 10px;
		}

		&.text-center {
			justify-content: center;
		}
	}
}

.btn {
	font-size: 16px;
	line-height: 1;
	padding: 10px 20px;
	min-width: 150px;
	font-family: $AdorHR;
	color: $pink;
	font-weight: bold;
	text-align: center;
	border: 1px solid #fff;
	background-color: #fff;
	border-radius: 3px;
	cursor: pointer;
	transition: .3s;

	&:hover {
		color: #fff;
		box-shadow: inset 0 -4em 0 $pink;
	}

	&-white-violet {
		color: $l-violet;

		&:hover {
			box-shadow: inset 0 -4em 0 $l-violet;
		}
	}

	&-pink {
		color: #fff;
		background-color: $pink;
		border-color: $pink;

		&:hover {
			color: $pink;
			box-shadow: inset 0 -4em 0 #fff;
		}
	}

	&-violet {
		color: #fff;
		background-color: $l-violet;
		border-color: $l-violet;

		&:hover {
			color: $l-violet;
			box-shadow: inset 0 -4em 0 #fff;
		}
	}

	&-d-violet {
		color: #fff;
		background-color: $violet;
		border-color: $violet;

		&:hover {
			color: $violet;
			box-shadow: inset 0 -4em 0 #fff;
		}
	}

	&-gray {
		color: #fff;
		background-color: #707070;
		border-color: #707070;

		&:hover {
			color: #707070;
			box-shadow: inset 0 -4em 0 #fff;
		}
	}

	&-gray-2 {
		color: #fff;
		background-color: #A5A1A1;
		border-color: #A5A1A1;

		&:hover {
			color: #A5A1A1;
			box-shadow: inset 0 -4em 0 #fff;
		}
	}

	&-big {
		@include fs(24);
		min-width: 220px;
		padding: 15px 20px;
	}

	&.icon-left {
		position: relative;
		padding-left: 40px;

		img {
			position: absolute;
			left: 7px;
			top: 50%;
			transform: translateY(-50%);
			filter: brightness(10);
			height: 20px;
			max-width: 25px;
		}

		&:hover img {
			filter: none;
		}
	}

	&.icon-right {
		position: relative;
		padding-right: 40px;

		img {
			position: absolute;
			right: 7px;
			top: 50%;
			transform: translateY(-50%);
			filter: brightness(20);
			height: 20px;
			max-width: 25px;
		}

		&:hover img {
			filter: none;
		}
	}
}

.promo-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: $pink;
	color: #fff;
	transform: rotate(17deg);
	font-size: 11px;
}

.btn-more {
	padding: 15px 0;
	transition: opacity .3s;

	img {
		filter: brightness(0);
	}

	&:hover {
		opacity: .7;
	}
}

.text-item {
	@include fs(21);
	line-height: 1.5;
	margin-bottom: 30px;

	p:not(:last-child) {
		margin-bottom: 30px;
	}

	a:not(.btn) {
		color: $violet;
		transition: color 0.3s;

		&:hover {
			color: $gray;
		}
	}

	&-big {
		@include fs(24);
	}
}

.calendar {
	padding: 30px;
	text-align: center;
	background-color: #F6EFF5;
}

.float-right {
	float: right;
}

.table-responsive {
	overflow-x: auto;
}

.table {
	width: 100%;

	td {
		padding: 15px 15px 15px 0;
		width: 25%;
	}
}

.opacity-80 {
	opacity: 80%;
}

.h-100 {
	height: 100% !important;
	margin-bottom: 0 !important;
}

.h-auto {
	height: auto !important;
}

.bold {
	font-weight: bold;
}

.circle {
	display: inline-block;
	border-radius: 50%;
	overflow: hidden;
}

img.icon {
	display: inline-block;
	vertical-align: middle;
}

.tabs {

	&-wrapper {}

	&-content {

		&-item {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	&-buttons {

		&-item {
			&.mixitup-control-active {
				background-color: #fff;
				color: $violet;
			}
		}
	}
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

.required-text {
	display: block;
	font-size: 14px;
	font-style: italic;
	color: #000;
}

.flex-column {
	display: flex;
	flex-direction: column;
}