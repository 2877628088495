/**
FORFAITS
 */
.expand-block.bg-empty .expand-block-title {
    height: auto;
}
/**
COOKIE CONSENT
 */
#cookie-consent-container {
    position: fixed;
    padding: 20px;
    background-color: #df3388;
    opacity: 0.9;
    bottom: 0;
    height: 100px;
    border-top-right-radius: 20px;
}
/**
STRIPE
 */
#stripe-card-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 20px;
    margin-bottom: 50px;
    border: 1px solid #707070;
    border-radius: .25rem;
}
/**
NAVBAR
 */
.header-lang a {
    width: 50px;
}
.header-lang a.active img {
    box-shadow: 0 3px 5px #DF3388;
}
/**
STEP FORM
 */
.inscription-step {
    display: none;
    border: 0px solid black;
    padding: 20px;
}
.inscription-step.active {
    display: block;
}
/**
FORMULAIRE INSCRIPTION
 */
#entreprise-sirene-prototype {
    display: none;
}
/**
LABEL
 */
form label {
    font-weight: 600;
}
form label.required::after {
    content: '*';
}
/**
CURSOR
 */
.pointer {
    cursor: pointer;
}