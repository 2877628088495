/* reset styles */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: ''; }

input:focus, textarea:focus, a:focus, button:focus, select:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del, s, strike {
  text-decoration: line-through; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
  margin: 0; }

/* fonts */
@font-face {
  font-family: 'Futura';
  src: url("../fonts/Futura-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Futura';
  src: url("../fonts/Futura-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'AdorHairline';
  src: url("../fonts/AdorHairline-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'AdorHairline';
  src: url("../fonts/AdorHairline-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'AdorHairline';
  src: url("../fonts/AdorHairline-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'AdorHairline';
  src: url("../fonts/AdorHairline-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'AdorHairline';
  src: url("../fonts/AdorHairline-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'AdorHairline';
  src: url("../fonts/AdorHairline-Medium-Italic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Arboria-Book';
  src: url("../fonts/Arboria-Book.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Copperplate';
  src: url("../fonts/Copperplate.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Geometos';
  src: url("../fonts/Geometos.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

/* structure */
html {
  height: 100%; }

.no-flexbox body {
  min-height: 100%;
  height: 100%;
  display: block !important; }

.page-wrapper, .footer {
  width: 100%;
  margin: 0 auto; }
  .no-flexbox .page-wrapper, .no-flexbox .footer {
    width: auto; }

.page-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh; }
  .lte-ie-11 .page-wrapper, .lt-ie-11 .page-wrapper {
    min-height: 0; }
  .no-flexbox .page-wrapper {
    display: block !important;
    width: auto;
    min-height: 100%;
    height: auto !important;
    height: 100%; }

.page-body {
  -ms-flex: 1;
  flex: 1; }
  .page-body.flex-none {
    -ms-flex: none;
    flex: none; }

/*sprite svg*/
/* variables */
.color-pink {
  color: #DF3388 !important; }

.color-gray {
  color: #A5A1A1 !important; }

.color-violet {
  color: #7C0E6F !important; }

.color-l-violet {
  color: #963E8B !important; }

.color-white {
  color: #fff !important; }

.color-black {
  color: #000 !important; }

.color-green {
  color: #91B508 !important; }

.bg-l-gray {
  background-color: #E5E5E5; }

.bg-gray {
  background-color: #CBCBCB; }

.bg-l-gray {
  background-color: #EFEDED; }

.bg-white {
  background-color: #fff; }

.bg-pink {
  background-color: #DF3388; }

.bg-violet {
  background-color: #7C0E6F; }

.bg-l-violet {
  background-color: #963E8B; }

.bg-empty {
  background-color: transparent !important; }

.mb-auto {
  margin-bottom: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-50 {
  margin-top: 50px !important; }

.ml-auto {
  margin-left: auto; }

.pb-30 {
  padding-bottom: 30px; }

.expand-block {
  position: relative;
  margin-bottom: 10px; }
  .expand-block-title, .expand-block-content {
    padding: 15px 15px 25px 15px;
    color: #fff;
    background: linear-gradient(to bottom, #F820EB, #900B4C);
    border-radius: 20px; }
  .expand-block-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.625rem;
    transition: box-shadow .3s; }
    .expand-block-title.a:hover {
      box-shadow: 0 0 5px #000; }
    .expand-block-title:after {
      position: absolute;
      content: "";
      left: 50%;
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 5px;
      background-image: url(../images/icons/icon-arrow-down.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 14px;
      transition: transform .3s; }
    .expand-block-title.bg-violet {
      background: linear-gradient(to bottom, #931C82, #4A0E41); }
    .expand-block-title.bg-l-violet {
      background: linear-gradient(to bottom, #C466BE, #701A44); }
    .expand-block-title.bg-blue {
      background: linear-gradient(to bottom, #9489C5, #4A4563); }
    .expand-block-title.bg-d-pink {
      background: linear-gradient(to bottom, #D23080, #691840); }
  .expand-block-content {
    position: absolute;
    top: 105%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 0 5px #000;
    z-index: 10;
    transition: opacity .3s, visibility .3s; }
    .expand-block-content.bg-violet {
      background: linear-gradient(to bottom, #931C82, #4A0E41); }
    .expand-block-content.bg-l-violet {
      background: linear-gradient(to bottom, #C466BE, #701A44); }
    .expand-block-content.bg-blue {
      background: linear-gradient(to bottom, #9489C5, #4A4563); }
    .expand-block-content.bg-d-pink {
      background: linear-gradient(to bottom, #D23080, #691840); }
  .expand-block.active .expand-block-title:after {
    -ms-transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg); }
  .expand-block.active .expand-block-content {
    opacity: 1;
    visibility: visible; }
  .expand-block.bg-empty {
    height: 100%; }
    .expand-block.bg-empty .expand-block-title {
      height: 100%;
      background: transparent;
      color: #7C0E6F;
      border: 1px solid #DF3388;
      text-transform: none; }
      .expand-block.bg-empty .expand-block-title:after {
        filter: brightness(0) saturate(100%) invert(15%) sepia(37%) saturate(6328%) hue-rotate(292deg) brightness(82%) contrast(103%); }

.title-mega {
  font-size: 3.4375rem; }

.title-large {
  font-size: 2.625rem; }

.title-big {
  font-size: 2.375rem; }

.title-middle {
  font-size: 2rem; }

.title-small {
  font-size: 1.75rem; }

.title-w-border {
  padding-bottom: 5px;
  border-bottom: 0.5px solid #707070;
  margin-bottom: 30px; }

.title-w-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  color: #963E8B;
  font-weight: bold; }
  .title-w-icon img {
    display: inline-block;
    margin-right: 15px; }
  .title-w-icon .btn {
    margin-left: auto;
    margin-top: 10px; }
  .title-w-icon.text-center {
    -ms-flex-pack: center;
    justify-content: center; }

.btn {
  font-size: 16px;
  line-height: 1;
  padding: 10px 20px;
  min-width: 150px;
  font-family: "AdorHairline", Arial, sans-serif;
  color: #DF3388;
  font-weight: bold;
  text-align: center;
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  transition: .3s; }
  .btn:hover {
    color: #fff;
    box-shadow: inset 0 -4em 0 #DF3388; }
  .btn-white-violet {
    color: #963E8B; }
    .btn-white-violet:hover {
      box-shadow: inset 0 -4em 0 #963E8B; }
  .btn-pink {
    color: #fff;
    background-color: #DF3388;
    border-color: #DF3388; }
    .btn-pink:hover {
      color: #DF3388;
      box-shadow: inset 0 -4em 0 #fff; }
  .btn-violet {
    color: #fff;
    background-color: #963E8B;
    border-color: #963E8B; }
    .btn-violet:hover {
      color: #963E8B;
      box-shadow: inset 0 -4em 0 #fff; }
  .btn-d-violet {
    color: #fff;
    background-color: #7C0E6F;
    border-color: #7C0E6F; }
    .btn-d-violet:hover {
      color: #7C0E6F;
      box-shadow: inset 0 -4em 0 #fff; }
  .btn-gray {
    color: #fff;
    background-color: #707070;
    border-color: #707070; }
    .btn-gray:hover {
      color: #707070;
      box-shadow: inset 0 -4em 0 #fff; }
  .btn-gray-2 {
    color: #fff;
    background-color: #A5A1A1;
    border-color: #A5A1A1; }
    .btn-gray-2:hover {
      color: #A5A1A1;
      box-shadow: inset 0 -4em 0 #fff; }
  .btn-big {
    font-size: 1.5rem;
    min-width: 220px;
    padding: 15px 20px; }
  .btn.icon-left {
    position: relative;
    padding-left: 40px; }
    .btn.icon-left img {
      position: absolute;
      left: 7px;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      filter: brightness(10);
      height: 20px;
      max-width: 25px; }
    .btn.icon-left:hover img {
      filter: none; }
  .btn.icon-right {
    position: relative;
    padding-right: 40px; }
    .btn.icon-right img {
      position: absolute;
      right: 7px;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      filter: brightness(20);
      height: 20px;
      max-width: 25px; }
    .btn.icon-right:hover img {
      filter: none; }

.promo-circle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #DF3388;
  color: #fff;
  -ms-transform: rotate(17deg);
  transform: rotate(17deg);
  font-size: 11px; }

.btn-more {
  padding: 15px 0;
  transition: opacity .3s; }
  .btn-more img {
    filter: brightness(0); }
  .btn-more:hover {
    opacity: .7; }

.text-item {
  font-size: 1.3125rem;
  line-height: 1.5;
  margin-bottom: 30px; }
  .text-item p:not(:last-child) {
    margin-bottom: 30px; }
  .text-item a:not(.btn) {
    color: #7C0E6F;
    transition: color 0.3s; }
    .text-item a:not(.btn):hover {
      color: #707070; }
  .text-item-big {
    font-size: 1.5rem; }

.calendar {
  padding: 30px;
  text-align: center;
  background-color: #F6EFF5; 
  }

.float-right {
  float: right; }

.table-responsive {
  overflow-x: auto; }

.table {
  width: 100%; }
  .table td {
    padding: 15px 15px 15px 0;
    width: 25%; }

.opacity-80 {
  opacity: 80%; }

.h-100 {
  height: 100% !important;
  margin-bottom: 0 !important; }

.h-auto {
  height: auto !important; }

.bold {
  font-weight: bold; }

.circle {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden; }

img.icon {
  display: inline-block;
  vertical-align: middle; }

.tabs-content-item {
  display: none; }
  .tabs-content-item.active {
    display: block; }

.tabs-buttons-item.mixitup-control-active {
  background-color: #fff;
  color: #7C0E6F; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

.required-text {
  display: block;
  font-size: 14px;
  font-style: italic;
  color: #000; }

.flex-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

/* main styles */
html {
  font-size: 16px; }

body {
  font-family: "AdorHairline", Arial, sans-serif;
  font-weight: normal;
  color: #000;
  background-color: #fff;
  letter-spacing: 0;
  line-height: 1.3; }
  body.w-mobile-menu {
    overflow: hidden; }
  body.bg-black {
    background-color: #000;
    color: #fff; }
    body.bg-black .header-menu,
    body.bg-black .header-user a {
      border-color: #fff; }
    body.bg-black .header-menu-item a {
      color: #fff; }
    body.bg-black .header a img {
      transition: filter .1s; }
    body.bg-black .header-social a img {
      filter: brightness(1.5); }
    body.bg-black .header-menu-item a:hover img {
      filter: brightness(0) saturate(100%) invert(34%) sepia(78%) saturate(3050%) hue-rotate(310deg) brightness(90%) contrast(94%); }
    body.bg-black .header-user a img,
    body.bg-black .header-menu-item a img,
    body.bg-black .header-social a:hover img,
    body.bg-black .actualites-social .header-social a img {
      filter: brightness(10); }
    body.bg-black .header-user a:hover,
    body.bg-black .header-user a.active {
      background-color: #DF3388;
      border-color: #DF3388; }
    body.bg-black .actualites-social .header-social a:hover img {
      filter: brightness(1.5); }

img {
  max-width: 100%;
  height: auto; }

a,
button {
  display: inline-block;
  -webkit-transform: translateZ(0);
  text-decoration: none; }

.image-object {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.row-0 {
  margin-left: 0;
  margin-right: 0; }
  .row-0 [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.row-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row-5 [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }

.row-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row-10 [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.row-25 {
  margin-left: -25px;
  margin-right: -25px; }
  .row-25 [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px; }

.slick-prev:before {
  display: block;
  content: "";
  background-image: url(../images/icons/icon-arrow-left.svg);
  background-size: cover; }

.slick-next:before {
  display: block;
  content: "";
  background-image: url(../images/icons/icon-arrow-right.svg);
  background-size: cover; }

.slick-prev:before,
.slick-next:before,
.slick-next,
.slick-prev {
  width: 20px;
  height: 35px; }

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  opacity: 1;
  transition: .3s; }

.slick-next {
  right: 25px; }

.slick-prev {
  left: 25px; }

.slick-prev,
.slick-next {
  filter: brightness(10);
  transition: filter .3s;
  z-index: 1; }
  .slick-prev:hover,
  .slick-next:hover {
    filter: brightness(1); }

.slick-dots {
  bottom: 15px; }

.slick-dots li button:before {
  color: #DF3388;
  font-size: 10px;
  opacity: .7;
  font-size: 20px;
  text-shadow: 0 0 5px #fff;
  transition: .3s; }

.slick-dots li.slick-active button:before {
  opacity: 1; }

.form-control {
  border: none;
  border-radius: 0;
  width: 100%;
  padding: 5px 15px;
  background-color: #fff;
  color: #000;
  font-size: 1.25rem;
  font-family: "AdorHairline", Arial, sans-serif;
  font-weight: bold;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.25);
  transition: .3s; }

.form-control:not(textarea) {
  height: 58px; }

.form-control:-ms-input-placeholder {
  color: #A4A1A1;
  font-family: "AdorHairline", Arial, sans-serif;
  font-weight: bold; }

.form-control::placeholder {
  color: #A4A1A1;
  font-family: "AdorHairline", Arial, sans-serif;
  font-weight: bold; }

.form-control:focus {
  box-shadow: inset 0 0 0 2px #DF3388; }

.form-group {
  display: block;
  margin-bottom: 40px; }

.form-radio {
  position: relative;
  display: block;
  padding-left: 45px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #A8A8A8;
  cursor: pointer;
  transition: .3s; }
  .form-radio input {
    display: none; }
  .form-radio span {
    position: absolute;
    left: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 3px solid #A8A8A8; }
    .form-radio span:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 60%;
      height: 60%;
      border-radius: 50%;
      background-color: #DF3388;
      opacity: 0;
      transition: opacity .3s; }
  .form-radio input:checked + span {
    border-color: #DF3388; }
    .form-radio input:checked + span:after {
      opacity: 1; }
  .form-radio.d-i-block {
    margin-right: 30px; }

.form-file {
  position: relative;
  cursor: pointer;
  transition: color .3s;
  font-size: 1.25rem;
  padding-left: 45px; }
  .form-file:hover {
    color: #000; }
  .form-file input {
    display: none; }
  .form-file img {
    position: absolute;
    left: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .form-file.d-i-block {
    margin-right: 30px; }

.form-checkbox {
  position: relative;
  display: block;
  padding-left: 45px;
  font-size: 1.375rem;
  font-style: italic;
  color: #707070;
  line-height: 26px;
  transition: .3s; }
  .form-checkbox input {
    display: none; }
  .form-checkbox span {
    position: absolute;
    left: 0;
    top: 5px;
    display: block;
    width: 26px;
    height: 26px;
    border: 3px solid #A8A8A8;
    cursor: pointer; }
    .form-checkbox span:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 60%;
      height: 60%;
      background-color: #DF3388;
      opacity: 0;
      transition: opacity .3s; }
  .form-checkbox input:checked + span {
    border-color: #DF3388; }
    .form-checkbox input:checked + span:after {
      opacity: 1; }

.form-password {
  position: relative; }
  .form-password-toggle {
    position: absolute;
    right: 15px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
    filter: grayscale(1) brightness(3.5); }

.form-date {
  position: relative; }
  .form-date:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    right: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url("../images/icons/icon-calendar-2.svg");
    background-size: contain;
    background-repeat: no-repeat; }

.input-w-icon {
  position: relative; }
  .input-w-icon .form-control {
    padding-left: 50px; }
  .input-w-icon .icon {
    position: absolute;
    content: "";
    max-width: 20px;
    max-height: 20px;
    left: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1; }

.form-label {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 1.3125rem; }

.form-info {
  position: relative;
  padding: 40px 15px;
  text-align: center;
  font-weight: normal;
  line-height: 1.7; }
  .form-info:not(.h-100) {
    height: auto !important; }
  .form-info .close {
    position: absolute;
    right: 15px;
    top: 12px;
    transition: filter .3s; }
    .form-info .close:hover {
      filter: grayscale(1); }

.clearfix:after {
  content: "";
  clear: both;
  display: table; }

.section {
  margin-bottom: 4.5rem; }
  .section-title {
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold; }
    .section-title .btn {
      margin-top: 15px;
      border-radius: 10px; }

.iframe-wrapper {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden; }
  .iframe-wrapper iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

.menu-toggler {
  display: none; }
  .menu-toggler span {
    position: relative;
    display: block;
    width: 35px;
    height: 3px;
    background-color: #000;
    -ms-transform-origin: 4px 0px;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }
  .menu-toggler span:not(:last-child) {
    margin-bottom: 8px; }
  .menu-toggler span:first-child {
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%; }
  .menu-toggler.active span:nth-child(2) {
    -ms-transform-origin: 0% 100%;
    transform-origin: 0% 100%; }
  .menu-toggler.active span:first-child {
    opacity: 1;
    -ms-transform: rotate(45deg) translate(1px, -4px);
    transform: rotate(45deg) translate(1px, -4px); }
  .menu-toggler.active span:nth-child(2) {
    opacity: 0;
    -ms-transform: rotate(0deg) scale(0.2, 0.2);
    transform: rotate(0deg) scale(0.2, 0.2); }
  .menu-toggler.active span:nth-child(3) {
    -ms-transform: rotate(-45deg) translate(2px, -2px);
    transform: rotate(-45deg) translate(2px, -2px); }

.border-bottom {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #707070; }
  .border-bottom.color-white {
    border-color: #fff; }

.border-right-abs {
  position: relative;
  padding-right: 15px; }
  .border-right-abs:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #707070; }

.border-radius {
  width: 100%;
  border-radius: 50px;
  overflow: hidden; }

.link-more {
  color: #DF3388;
  font-weight: bold;
  font-size: 1rem;
  transition: color .3s; }
  .link-more:hover {
    color: #000; }
  .link-more.color-white:hover {
    color: #DF3388 !important; }

.image-w-link {
  display: block;
  filter: brightness(0.9);
  transition: filter .3s; }
  .image-w-link img {
    display: block;
    width: 100%; }
  .image-w-link:hover {
    filter: brightness(1); }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.v-align-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.v-align-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center; }

.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: none !important; }

.d-i-block {
  display: inline-block !important; }

.container-big {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px; }

.logo {
  position: absolute;
  top: 25%;
  left: 0;
  width: 20%;
  min-width: 100px;
  padding: 30px 30px 30px 50px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
  z-index: 10;
  transition: .2s; }
  .logo:hover {
    background-color: #fff; }

.border-decor {
  border-left: 20px solid #DF3388;
  padding: 10px 0 10px 20px;
  font-weight: bold;
  color: #fff; }
  .border-decor-violet {
    border-color: #7C0E6F; }
  .border-decor-l-violet {
    border-color: #963E8B; }
  .border-decor-white {
    border-color: #fff; }

.icon-video {
  position: absolute;
  color: #7C0E6F;
  z-index: 1; }
  .icon-video img {
    width: auto;
    display: inline-block; }
  .icon-video.white {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold; }
    .icon-video.white img {
      filter: brightness(100); }

.nice-select:after {
  border-bottom: 3px solid #999;
  border-right: 3px solid #999;
  height: 10px;
  width: 10px; }

.nice-select .list {
  border: 2px solid #df3388;
  border-radius: 0; }

/* header */
.header {
  padding: 80px 0 50px; }
  .header-top {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 25px; }
  .header-lang {
    margin-right: 20px; }
    .header-lang a {
      display: inline-block;
      margin-right: 10px; }
      .header-lang a img {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5); }
      .header-lang a:hover img {
        filter: grayscale(1) !important; }
  .header-title {
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-size: 3.125rem;
    line-height: 1;
    font-weight: 300;
    letter-spacing: 7px;
    text-transform: uppercase; }
  .header-menu {
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #9B9B9B;
    border-radius: 35px;
    padding: 0 15px; }
    .header-menu .header-social {
      display: none; }
    .header-menu-wrapper {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
    .header-menu-item {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      transition: background-color .3s; }
      .header-menu-item a {
        padding: 15px;
        color: #000;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: text-shadow .3s, color .3s; }
        .header-menu-item a img {
          max-width: 28px; }
        .header-menu-item a:hover {
          text-shadow: 0 0 1px #DF3388;
          color: #DF3388 !important; }
      .header-menu-item.active a {
        text-shadow: 0 0 1px #DF3388;
        color: #DF3388 !important; }
    .header-menu .submenu {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      top: 100%;
      padding: 10px 0;
      background-color: rgba(157, 155, 155, 0.75);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      z-index: 20;
      transition: opacity .3s, visibility .3s, background-color .3s; }
      .header-menu .submenu a {
        display: block;
        font-weight: 500;
        color: #fff;
        font-size: 14px;
        padding: 5px 15px;
        text-shadow: none;
        white-space: nowrap;
        transition: color .3s, background-color .3s; }
        .header-menu .submenu a:hover {
          background-color: #fff;
          color: #DF3388; }
      .header-menu .submenu-toggle-mobile {
        position: absolute;
        left: 20%;
        top: 0;
        font-size: 25px;
        font-weight: bold; }
    .header-menu .submenu-toggle-mobile.active {
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
    .header-menu .submenu-toggle-mobile.active + .submenu {
      display: block;
      position: inherit;
      top: auto;
      opacity: 1;
      visibility: visible; }
  .header-social a {
    margin-right: 5px; }
  .header-user {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex; }
    .header-user a {
      display: -ms-flexbox;
      display: flex;
      margin-left: 15px;
      width: 65px;
      height: 65px;
      border: 1px solid #A5A1A1;
      border-radius: 50%;
      transition: border-color .3s, background-color .3s; }
      .header-user a img {
        margin: auto;
        max-width: 60%;
        transition: filter .3s; }
      .header-user a:hover, .header-user a.active {
        border-color: #000; }
        .header-user a:hover img, .header-user a.active img {
          filter: brightness(0); }
  .header ul {
    list-style-type: none; }
  .header a img {
    transition: filter .3s; }
  .header a:hover img {
    filter: brightness(0); }

.header-menu-toggler {
  transition: .3s; }

.header-menu-toggler span {
  position: relative;
  display: block;
  width: 35px;
  height: 3px;
  background-color: #7C0E6F;
  z-index: 1;
  -ms-transform-origin: 4px 0px;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; }

.header-menu-toggler span:not(:last-child) {
  margin-bottom: 10px; }

.header-menu-toggler span:first-child {
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%; }

.header-menu-toggler.active span:nth-child(2) {
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%; }

.header-menu-toggler.active span:first-child {
  opacity: 1;
  -ms-transform: rotate(45deg) translate(3px, -4px);
  transform: rotate(45deg) translate(3px, -4px); }

.header-menu-toggler.active span:nth-child(2) {
  opacity: 0;
  -ms-transform: rotate(0deg) scale(0.2, 0.2);
  transform: rotate(0deg) scale(0.2, 0.2); }

.header-menu-toggler.active span:nth-child(3) {
  -ms-transform: rotate(-45deg) translate(4px, -2px);
  transform: rotate(-45deg) translate(4px, -2px); }

.header-menu-toggler.active {
  z-index: 110; }

.fly-block {
  position: absolute;
  width: 100%;
  top: 300px;
  height: 0;
  transition: .3s;
  z-index: 10; }
  .fly-block-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 80px;
    z-index: 10; }
    .fly-block-buttons .btn {
      height: 80px;
      min-width: 300px;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
      padding: 25px;
      border-radius: 10px 10px 0 0;
      transition: .2s; }
      .fly-block-buttons .btn:first-child {
        margin-right: 10px; }
  .fly-block.scroll {
    position: fixed;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: none;
    transform: none; }
    .fly-block.scroll .fly-block-buttons {
      -ms-flex-pack: center;
      justify-content: center;
      width: 40px; }
      .fly-block.scroll .fly-block-buttons .btn {
        height: 40px;
        padding: 10px 15px;
        min-width: 200px;
        font-size: 1.125rem; }

.page .fly-block {
  top: 310px; }

.promo {
  position: relative; }
  .promo-top {
    position: relative;
    margin-bottom: 15px; }
  .promo-item {
    position: relative; }
    .promo-item-big {
      height: calc(100vw*0.6);
      max-height: 1200px;
      min-height: 300px; }
    .promo-item-small {
      height: 50%;
      max-height: 600px;
      min-height: 300px; }
    .promo-item img {
      filter: brightness(0.8); }
    .promo-item .image-object {
      background-position: center 30%; }
  .promo-content {
    position: absolute;
    right: 7%;
    bottom: 7%;
    font-weight: bold;
    max-width: 80%;
    color: #fff; }
    .promo-content .btn {
      margin-top: 10px; }
  .promo-title {
    font-size: 4.375rem;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 1; }
  .promo-text {
    font-size: 1.25rem; }
  .promo-item-small .promo-content {
    border-color: #963E8B;
    left: 13%;
    top: 10%;
    right: auto;
    bottom: auto;
    max-width: 65%; }
  .promo-item-small .promo-title {
    font-size: 3rem; }
  .promo-item-small .promo-text {
    font-size: 1.125rem; }
  .promo-slider-item {
    position: relative;
    width: 50%;
    height: calc(100vw*0.25);
    max-height: 480px; }
    .promo-slider-item img {
      filter: brightness(0.6); }
    .promo-slider-item .icon-video {
      left: 70px;
      top: 50px; }
  .promo-slider-content {
    position: absolute;
    max-width: 80%;
    width: 600px;
    left: 50%;
    bottom: 15%;
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    color: #fff; }
    .promo-slider-content span {
      position: relative;
      display: block;
      font-size: 1.75rem;
      font-family: "Futura", Arial, sans-serif;
      font-weight: bold;
      text-align: center; }
      .promo-slider-content span:after {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        left: 0;
        top: 2.5rem;
        background-color: #fff; }
    .promo-slider-content .btn {
      margin-left: auto;
      margin-top: 10px; }
  .promo .section-title {
    font-weight: normal; }

.interviews-slider {
  margin: 0 -10px; }
  .interviews-slider-item {
    position: relative;
    padding: 0 10px; }
    .interviews-slider-item .icon-video {
      left: 40px;
      top: 35px; }
  .interviews-slider-title {
    font-size: 1.625rem;
    font-weight: bold;
    margin-top: 10px;
    text-transform: uppercase; }
    .interviews-slider-title .lowercase {
      font-size: .9em; }
    .interviews-slider-title .btn {
      font-size: 12px; }
  .interviews-slider .slick-prev,
  .interviews-slider .slick-next {
    top: 40%; }

.articles-list {
  margin-bottom: 20px; }
  .articles-list-wrapper.border-right-abs:after {
    right: -15px; }

.articles-item {
  margin-bottom: 30px; }
  .articles-item-bg {
    position: relative;
    padding: 15px 30px;
    height: 100%;
    min-height: 450px; }
    .articles-item-bg .image-object {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1; }
  .articles-item.align-justify {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%; }

.articles-image {
  height: calc(100vw * .25);
  max-height: 450px; }

.articles-title, .articles-date {
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000; }

.articles-title.title-w-border {
  padding-bottom: 5px;
  border-bottom: 0.5px solid #707070;
  margin-bottom: 30px; }

.articles-text {
  display: block;
  font-size: 1.3125rem;
  color: #000;
  margin-bottom: 20px;
  line-height: 1.5; }
  .articles-text p:not(:last-child) {
    margin-bottom: 15px; }

.articles-promo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.articles .promo-slider-item {
  -ms-flex: 0 0 calc(100vw*0.7);
  flex: 0 0 calc(100vw*0.7);
  max-width: 1200px; }

.articles-work-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  border: 1px solid #707070;
  padding: 20px 20px 10px; }
  .articles-work-item img {
    max-height: 180px;
    max-width: 70%; }
  .articles-work-item.big .articles-work-title {
    text-align: left; }
  .articles-work-item.big .articles-work-text i {
    font-size: .9em;
    font-weight: 500; }
  .articles-work-item.big span {
    display: inline-block; }

.articles-work-title {
  margin-top: 15px;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 10px !important; }
  .articles-work-title span {
    display: block;
    font-size: .85em;
    font-weight: normal; }

.articles-work-text {
  font-size: 1.5rem;
  margin-bottom: 10px; }

.articles-work .link-more {
  margin-left: auto; }

.articles-aside {
  padding-left: 15px; }
  .articles-aside .title-w-icon {
    margin-bottom: 30px;
    color: #0C9BE6;
    font-size: 1.5rem; }
  .articles-aside-item:not(:last-child) {
    margin-bottom: 20px; }
  .articles-aside-logo {
    margin-bottom: 20px; }
    .articles-aside-logo img {
      width: 170px; }

.companies-title {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px; }

.companies-slider {
  margin: 0 -15px; }
  .companies-slider .slick-prev,
  .companies-slider .slick-next {
    top: 45%; }
  .companies-slider-item {
    padding: 0 15px; }
    .companies-slider-item a {
      display: -ms-flexbox;
      display: flex;
      height: 230px;
      border: 1px solid #707070;
      border-radius: 50px;
      transition: border-color .3s; }
      .companies-slider-item a:hover {
        border-color: #DF3388; }
    .companies-slider-item img {
      margin: auto;
      max-width: 60%;
      max-height: 80%; }
  .companies-slider .slick-prev,
  .companies-slider .slick-next {
    filter: brightness(1); }
    .companies-slider .slick-prev:hover,
    .companies-slider .slick-next:hover {
      filter: brightness(0); }

.projects-title {
  margin-bottom: 30px;
  text-transform: uppercase; }

.projects-slider-item a {
  display: block;
  height: 250px; }

.projects-slider-text {
  padding: 30px 10px 0 0;
  font-size: 1.125rem;
  font-style: italic; }

.projects-slider .slick-next {
  right: 15px; }

.projects-slider .slick-prev {
  left: 15px; }

.projects-slider .slick-prev,
.projects-slider .slick-next {
  top: 125px; }

.projects-slider .slick-list {
  position: relative;
  border-radius: 50px 50px 0 0; }
  .projects-slider .slick-list:before, .projects-slider .slick-list:after {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    top: 200px;
    left: 0;
    background-image: url(../images/mask-circle.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1; }
  .projects-slider .slick-list:after {
    position: absolute;
    content: "";
    left: auto;
    right: 0;
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1); }

.footer {
  background-color: #998EC0;
  padding: 50px 0;
  color: #fff;
  font-weight: bold;
  font-size: 1.125rem; }
  .footer-inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px; }
  .footer-logo {
    width: 15%;
    margin-right: 15px; }
  .footer-text {
    text-align: center;
    margin-bottom: 15px; }
    .footer-text span {
      display: block;
      font-size: 1.625rem;
      text-transform: uppercase; }
  .footer-form * {
    vertical-align: middle; }
  .footer-form .form-control {
    float: left;
    width: 48%;
    margin-right: 4%;
    font-size: 1.125rem; }
    .footer-form .form-control:-ms-input-placeholder {
      text-align: center;
      font-size: 1.125rem; }
    .footer-form .form-control::placeholder {
      text-align: center;
      font-size: 1.125rem; }
  .footer-form .btn {
    float: left;
    height: 58px;
    font-size: 1.125rem;
    font-weight: bold;
    width: 48%;
    border-width: 2px; }
  .footer-menu-wrapper {
    text-align: center;
    margin-right: 15px; }
  .footer-menu-buttons {
    list-style-type: none;
    margin: 15px 0 30px; }
    .footer-menu-buttons li {
      display: inline-block; }
    .footer-menu-buttons .btn {
      padding: 7px;
      min-width: 0;
      margin: 0 3px 5px 0; }
  .footer-menu li {
    display: inline-block;
    list-style-type: none; }
    .footer-menu li:not(:last-child):after {
      display: inline-block;
      content: "-";
      margin-left: 5px;
      margin-right: 2px; }
  .footer-menu a {
    color: #fff;
    transition: color .3s; }
    .footer-menu a:hover {
      color: #000; }

.promo-page .promo-item-small,
.promo-page .promo-item-big {
  height: calc(100vw*0.24);
  max-height: 600px;
  min-height: 300px; }

.promo-page .promo-item-big .promo-content {
  left: 33%;
  top: 10%;
  bottom: auto;
  max-width: 80%; }
  .promo-page .promo-item-big .promo-content.align-bottom {
    top: auto;
    bottom: 30px; }

.promo-page .promo-item-small .promo-content {
  max-width: 70%;
  top: auto;
  left: 50px;
  bottom: 30px; }

.promo-page .promo-item-small .promo-title {
  font-size: 1.75rem; }

.promo-page .logo {
  top: 50px; }

.promo-bottom [class*="col"]:nth-child(3) .promo-content, .promo-bottom [class*="col"]:nth-child(4) .promo-content {
  bottom: auto;
  top: 7%; }

.promo-right-slider .slick-prev {
  left: 15px; }

.promo-right-slider .slick-next {
  right: 15px; }

.emploi-about .title-big {
  text-transform: none; }

.emploi-about .articles-text a {
  color: #DF3388;
  font-weight: bold;
  text-decoration: underline;
  transition: color .3s; }
  .emploi-about .articles-text a:hover {
    color: #000; }

.emploi-about .title {
  text-align: center;
  margin-bottom: 50px;
  font-weight: bold; }

.text-top {
  top: 30px !important;
  bottom: auto !important; }

.text-bottom {
  bottom: 30px !important;
  top: auto !important; }

.offres .section-title {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase; }

.offres .articles-work [class*="col"] {
  margin-bottom: 30px; }

.offres .articles-work-item {
  max-width: 100%; }

.offres-advert .section-title {
  text-transform: none; }

.offres-advert-list {
  margin-bottom: 30px; }
  .offres-advert-list [class*="col"] {
    margin-bottom: 20px; }

.offres-advert-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #707070;
  padding: 30px 30px 5px 30px;
  text-align: center;
  font-size: 1.5rem;
  height: 100%; }
  .offres-advert-item .articles-title span {
    display: block;
    font-size: .7em;
    margin-top: 5px; }

.offres-promo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .offres-promo-item {
    position: relative;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    height: calc(100vw * .2);
    max-height: 360px; }
    .offres-promo-item img {
      filter: brightness(0.8); }
    .offres-promo-item:last-child .offres-promo-content {
      left: 30px; }
  .offres-promo-content {
    position: absolute;
    top: 30px;
    right: 30px;
    color: #fff;
    width: 450px;
    max-width: 90%; }
    .offres-promo-content .title-large {
      font-weight: bold; }

.offres-news .section-title {
  text-transform: none; }

.offres-news .articles-item {
  margin-bottom: 50px; }
  .offres-news .articles-item > .articles-title.title-w-border {
    font-weight: normal;
    margin-bottom: 30px; }

.offres-entreprise .section-title {
  font-weight: normal; }

.offres-entreprise-table {
  width: 100%;
  color: #7C0E6F;
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 30px; }
  .offres-entreprise-table tr,
  .offres-entreprise-table td {
    vertical-align: middle;
    height: 40px;
    border: 1px solid #fff;
    padding: 5px; }
  .offres-entreprise-table td .form-control {
    height: 45px;
    margin: 30px auto;
    max-width: 80%;
    white-space: nowrap; }
  .offres-entreprise-table td .btn {
    width: 150px;
    max-width: 100%;
    min-width: 0; }
  .offres-entreprise-table th:not(:first-child),
  .offres-entreprise-table td:not(:first-child) {
    text-align: center;
    border-left: 20px solid #fff; }
  .offres-entreprise-table td:first-child {
    text-align: right; }
  .offres-entreprise-table td:nth-child(2) {
    background-color: #E4C7E0; }
  .offres-entreprise-table td:nth-child(3) {
    background-color: #E5E2F1; }
  .offres-entreprise-table td:nth-child(4) {
    background-color: #FECCE6; }
  .offres-entreprise-table .bull {
    font-family: "Futura", Arial, sans-serif;
    font-size: 1.5rem; }
  .offres-entreprise-table .expand-block-title {
    font-family: "Arboria", Arial, sans-serif;
    padding-bottom: 15px;
    text-transform: uppercase;
    max-width: 80%;
    margin-bottom: 20px !important;
    font-weight: normal; }
    .offres-entreprise-table .expand-block-title span {
      min-width: 0 !important;
      font-size: .8em; }
    .offres-entreprise-table .expand-block-title:after {
      content: none; }

.offres-entreprise .expand-block-title {
  margin: 0 auto; }
  .offres-entreprise .expand-block-title span {
    display: block; }
  .offres-entreprise .expand-block-title.big {
    text-transform: uppercase;
    font-size: 3.125rem;
    font-family: "Arboria", Arial, sans-serif;
    border-radius: 30px;
    font-weight: normal;
    width: 33.33%;
    min-width: 300px;
    padding-bottom: 15px; }
    .offres-entreprise .expand-block-title.big span {
      font-size: .5em; }
    .offres-entreprise .expand-block-title.big:after {
      content: none; }

.offres-entreprise.entreprises-premium {
  background-color: transparent; }

.offres-entreprise .form-control {
  max-width: 330px;
  display: inline-block; }

.offres-entreprise-item {
  margin-bottom: 50px; }
  .offres-entreprise-item .expand-block {
    margin-bottom: 20px; }
    .offres-entreprise-item .expand-block-title {
      border-radius: 3rem;
      min-height: 140px; }
  .offres-entreprise-item .form-control {
    margin-top: 20px !important; }

.articles-aside .articles-aside-image {
  margin-bottom: 15px;
  max-height: 450px; }

.form {
  padding: 30px 5%;
  background-color: #F7F7F7;
  color: #A5A1A1; }

.video-item {
  position: relative;
  overflow: hidden;
  height: calc(100vw * .28);
  max-height: 530px; }
  .video-item .icon-video {
    left: 40px;
    top: 50px;
    font-size: 1.5rem;
    font-weight: bold; }
    .video-item .icon-video img {
      margin-right: 10px;
      vertical-align: middle; }
  .video-item-content {
    position: absolute;
    left: 40px;
    bottom: 30px;
    color: #000;
    width: 500px;
    max-width: 85%; }
  .video-item .articles-title span {
    display: block; }
    .video-item .articles-title span:first-child {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid #707070; }
    .video-item .articles-title span:last-child {
      font-family: "Futura", Arial, sans-serif; }

.articles-links a:not(.btn) {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  color: #7C0E6F; }
  .articles-links a:not(.btn):hover {
    color: #000; }
  .articles-links a:not(.btn) img {
    vertical-align: middle;
    margin-right: 5px; }

.actualites-item {
  height: 100%;
  margin-bottom: 0;
  transition: .3s; }
  .actualites-item:hover {
    border-color: #7C0E6F; }
  .actualites-item .image-object {
    border-radius: 20px;
    height: 400px;
    margin-bottom: 10px; }
  .actualites-item-date {
    color: #7C0E6F;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 10px; }
  .actualites-item .title-small {
    font-weight: bold;
    color: #000;
    margin-bottom: 15px; }

.actualites-feed .image-w-link img {
  width: 100%; }

.actualites-social .header-social a img {
  transition: filter .3s; }

.actualites-social .header-social a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(0%) hue-rotate(306deg) brightness(100%) contrast(100%); }

.actualites-article .text-item {
  margin: 30px 0; }
  .actualites-article .text-item img {
    border-radius: 50px;
    height: calc(100vw * .3);
    max-height: 350px;
    min-height: 250px;
    margin: 10px 0; }

.actualites-article-links a {
  margin-right: 30px;
  vertical-align: middle; }
  .actualites-article-links a img {
    transition: filter .3s; }
  .actualites-article-links a:hover img {
    filter: brightness(0); }

.entreprises-featured {
  border: 1px solid #707070;
  transition: box-shadow .3s; }
  .entreprises-featured-header {
    position: relative;
    background-color: #DF3388;
    padding: 20px 50px;
    color: #fff; }
    .entreprises-featured-header .icon {
      margin-right: 10px;
      height: 25px; }
      .entreprises-featured-header .icon:first-child, .entreprises-featured-header .icon:last-child {
        margin-right: 25px; }
    .entreprises-featured-header .promo-circle {
      position: absolute;
      width: 55px;
      height: 55px;
      font-size: .9rem;
      right: 30px;
      bottom: -5px;
      background-color: #fff;
      color: #DF3388;
      font-weight: bold;
      border: 1px solid #DF3388; }
  .entreprises-featured .entreprises-item-image {
    width: 100%;
    min-height: 420px; }
  .entreprises-featured .entreprises-item-text {
    width: 100%;
    padding: 0; }
    .entreprises-featured .entreprises-item-text .header-social {
      text-align: left; }
  .entreprises-featured-inner {
    padding: 30px; }
  .entreprises-featured .title-middle {
    margin-bottom: 20px;
    font-weight: bold; }
  .entreprises-featured .articles-text {
    color: #5D7884;
    line-height: 1.8;
    margin-bottom: 0; }

.entreprises-premium {
  text-align: center;
  padding: 15px 30px;
  background-color: rgba(150, 62, 139, 0.1);
  height: 100%;
  color: #7C0E6F; }
  .entreprises-premium-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #963E8B;
    text-transform: uppercase;
    margin-bottom: 20px; }
    .entreprises-premium-title span {
      display: block;
      color: #DF3388; }
  .entreprises-premium .title-large {
    font-family: "Arboria", Arial, sans-serif;
    color: #7C0E6F;
    text-transform: uppercase; }
    .entreprises-premium .title-large span {
      display: block;
      font-size: .5em;
      margin-bottom: 15px; }
  .entreprises-premium .btn {
    width: auto;
    min-width: 250px; }
  .entreprises-premium label {
    display: block;
    margin-bottom: 30px;
    font-size: 1.125rem; }
  .entreprises-premium .form-control {
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    color: #7C0E6F;
    font-size: 1.875rem;
    font-family: "Copperplate", Arial, sans-serif;
    font-weight: normal;
    margin-bottom: 20px; }
    .entreprises-premium .form-control .title-large {
      font-family: "Copperplate", Arial, sans-serif; }
  .entreprises-premium .prompt {
    font-size: 14px;
    display: block;
    margin-top: 20px; }

.entreprises-search-form {
  height: 100%; }
  .entreprises-search-form .nice-select {
    float: none; }
  .entreprises-search-form .title-small {
    font-weight: normal; }
  .entreprises-search-form label {
    display: inline-block;
    color: #7C0E6F;
    margin-bottom: 10px; }
  .entreprises-search-form .text-center .btn {
    vertical-align: middle;
    width: 100%; }

.entreprises-item {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: "Futura", Arial, sans-serif;
  color: #000;
  font-weight: medium; }
  .entreprises-item-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%; }
  .entreprises-item-header {
    position: relative;
    background-color: #7C0E6F;
    color: #fff;
    padding: 7px 20px; }
    .entreprises-item-header .icon {
      height: 25px;
      margin-right: 10px; }
      .entreprises-item-header .icon:first-child, .entreprises-item-header .icon:last-child {
        margin-right: 15px; }
    .entreprises-item-header .promo-circle {
      position: absolute;
      right: 15px;
      bottom: -10px; }
  .entreprises-item-image {
    position: relative;
    min-height: 100%;
    width: 48%;
    transition: filter .3s; }
    .entreprises-item-image:hover {
      filter: brightness(1.1); }
  .entreprises-item-logo {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 90%;
    height: 130px;
    background-color: #fff;
    padding: 10px; }
    .entreprises-item-logo img {
      margin: auto;
      max-height: 100%; }
  .entreprises-item-text {
    width: 52%;
    height: 100%;
    padding: 25px 15px 5px 15px;
    color: #5D7884; }
    .entreprises-item-text .title-small {
      margin-bottom: 20px;
      line-height: 1.2; }
    .entreprises-item-text .header-social {
      margin-top: auto;
      padding-top: 10px;
      text-align: right; }
    .entreprises-item-text .header-social a {
      margin-right: 0;
      width: 22px;
      height: 22px;
      transition: filter .3s; }
      .entreprises-item-text .header-social a:hover {
        filter: grayscale(1); }

.entreprises-single-properties .btn {
  display: inline-block;
  cursor: inherit;
  margin: 0 15px 15px 0;
  min-width: 15%; }
  .entreprises-single-properties .btn:hover {
    color: #fff;
    box-shadow: none; }
  .entreprises-single-properties .btn:nth-child(2) {
    opacity: .85; }
  .entreprises-single-properties .btn:nth-child(3) {
    opacity: .75; }
  .entreprises-single-properties .btn:nth-child(4) {
    opacity: .65; }
  .entreprises-single-properties .btn:nth-child(4) {
    opacity: .55; }

.entreprises-single-logo {
  margin-right: auto;
  max-height: 270px;
  padding: 15px; }

.entreprises-single .contacts-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 50px;
  min-height: 30px;
  font-size: 1.375rem;
  color: #262525;
  font-weight: normal;
  transition: color .3s; }
  .entreprises-single .contacts-item:not(:last-child) {
    margin-bottom: 20px; }
  .entreprises-single .contacts-item:not(div):hover {
    color: #DF3388; }
  .entreprises-single .contacts-item-icon {
    position: absolute;
    left: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
    width: 40px;
    margin-right: 5px;
    vertical-align: middle;
    text-align: center; }

.entreprises-single .text-item {
  font-size: 1.3125rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: auto; }
  .entreprises-single .text-item p:not(:last-child) {
    margin-bottom: 10px; }

.entreprises-single .header-social {
  text-align: center; }
  .entreprises-single .header-social a img {
    transition: filter .3s; }
  .entreprises-single .header-social a:hover img {
    filter: brightness(0); }

.entreprises-single-about .text-item img {
  display: block;
  margin: 10px 0;
  height: calc(100vw * .2);
  max-height: 300px;
  min-height: 200px; }

.entreprises-single-about .text-item-bg {
  background-color: #F6F5F5; }
  .entreprises-single-about .text-item-bg img {
    display: block;
    margin: 0; }
  .entreprises-single-about .text-item-bg .image-object {
    height: 100%; }

.entreprises-single-about .text-item-text {
  padding: 15px; }

.entreprises-single-about .title-small {
  font-weight: bold;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px; }
  .entreprises-single-about .title-small.lowercase {
    font-size: inherit;
    letter-spacing: 0; }

.entreprises-single-nav {
  margin-top: 50px; }
  .entreprises-single-nav .btn {
    margin-bottom: 10px; }

.entreprises-single-views {
  float: right;
  color: #7C0E6F;
  font-weight: bold; }
  .entreprises-single-views img {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle; }

.evenements-buttons {
  margin-left: auto; }
  .evenements-buttons .btn {
    margin-bottom: 10px;
    margin-left: 10px; }

.evenements-item {
  margin-bottom: 30px; }
  .evenements-item.big img {
    height: 470px; }
  .evenements-item-image {
    display: block;
    width: 100%; }
  .evenements-item-title {
    position: relative;
    font-size: 1.3125rem;
    margin-top: 15px;
    font-weight: bold;
    color: #7C0E6F;
    padding-left: 50px; }
    .evenements-item-title img {
      position: absolute;
      left: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 29px; }
    .evenements-item-title.color-gray {
      font-size: 1rem; }
      .evenements-item-title.color-gray .btn {
        float: right; }
    .evenements-item-title.small {
      font-size: 1.125rem; }

.evenements .entreprises-premium-title {
  text-transform: none;
  text-align: center;
  margin: 30px; }

.evenements .promo-item-small {
  min-height: inherit; }
  .evenements .promo-item-small img {
    display: block;
    width: 100%;
    height: 100%; }
  .evenements .promo-item-small:first-child {
    height: 57%;
    padding-bottom: 10px;
    max-height: 410px; }
  .evenements .promo-item-small:last-child {
    height: 43%;
    max-height: 290px; }

.evenements .promo-item-big {
  max-height: 690px;
  min-height: inherit;
  height: 100%; }
  .evenements .promo-item-big img {
    display: block;
    height: 100%; }

.evenements .promo-item {
  max-height: 580px;
  min-height: inherit;
  height: 100%; }
  .evenements .promo-item img {
    display: block;
    height: 100%;
    width: 100%; }

.evenements .video-item {
  height: auto;
  max-height: 680px; }
  .evenements .video-item .video-item-content {
    color: #fff;
    bottom: 50%;
    -ms-transform: translateY(50%);
    transform: translateY(50%); }
    .evenements .video-item .video-item-content .date {
      font-size: .85em;
      margin-bottom: 50px; }
    .evenements .video-item .video-item-content .big-title {
      font-size: 1.15em;
      margin-bottom: 40px; }
      .evenements .video-item .video-item-content .big-title b {
        display: block; }
  .evenements .video-item .btn {
    position: absolute;
    right: 7%;
    bottom: 30px; }

.evenements .text-item .title-big {
  font-weight: bold;
  color: #7C0E6F;
  margin-bottom: 20px;
  text-transform: uppercase; }

.evenements-speakers b,
.evenements-speakers strong {
  color: #7C0E6F; }

.tv-item-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 10px;
  font-size: 1.125rem; }
  .tv-item-title .btn {
    font-size: 12px;
    margin-left: auto; }
  .tv-item-title:not(:last-child) {
    font-weight: bold; }

.tv .border-radius {
  border-radius: 15px; }

.tv .interviews-slider .slick-prev,
.tv .tv-slider .slick-prev {
  left: -25px;
  top: 40%; }

.tv .interviews-slider .slick-next,
.tv .tv-slider .slick-next {
  right: -25px;
  top: 40%; }

.tv-next {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
  -ms-flex-pack: center;
  justify-content: center; }
  .tv-next-item {
    width: 16.66%;
    min-width: 158px;
    font-size: 1.3125rem;
    padding: 0 10px 30px 10px; }
  .tv-next-title {
    height: 125px;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px; }

.tv .entreprises-single-nav {
  margin: 30px 0 50px; }

.tv-single-item .entreprises-single-views {
  float: none;
  color: #fff;
  font-weight: normal; }
  .tv-single-item .entreprises-single-views:not(:last-child) {
    margin-right: 50px; }
  .tv-single-item .entreprises-single-views img {
    height: 40px;
    filter: brightness(10); }

.tv .evenements-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 30px; }

.tv-slider {
  margin: 0 -10px; }
  .tv-slider-item {
    padding: 0 5px; }

.membre-photo {
  display: block;
  text-align: center;
  margin-bottom: 4rem; }
  .membre-photo-title {
    position: relative;
    display: block;
    padding: 0 150px; }
    .membre-photo-title .btn {
      position: absolute;
      right: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  .membre-photo .circle {
    position: relative;
    width: 180px;
    height: 180px;
    margin-bottom: 35px;
    margin-top: -80px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: background-color .3s, border-color .3s;
    z-index: 1; }
    .membre-photo .circle:hover {
      border-color: #DF3388; }
    .membre-photo .circle.image-object {
      margin-left: auto;
      margin-right: auto; }
  .membre-photo .title-large {
    font-weight: bold; }
  .membre-photo-empty {
    color: #A5A1A1;
    font-size: 1.125rem;
    margin-bottom: 30px; }
    .membre-photo-empty .circle {
      margin-top: 0;
      background-color: #DF3388;
      margin-bottom: 15px;
      transition: background-color .3s; }
      .membre-photo-empty .circle:hover {
        background-color: #fff; }
    .membre-photo-empty img {
      position: absolute;
      left: 50%;
      top: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      max-width: 50%; }

.membre-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 6rem; }
  .membre-menu li {
    min-width: 14%;
    list-style-type: none;
    padding: 5px; }
    .membre-menu li.active .btn {
      background-color: #fff; }
    .membre-menu li.active .btn-pink {
      color: #DF3388; }
    .membre-menu li.active .btn-violet {
      color: #963E8B; }
    .membre-menu li.active .btn-d-violet {
      color: #7C0E6F; }
  .membre-menu .btn {
    display: block;
    border-radius: 10px;
    padding: 15px 20px; }

.membre .section-title {
  font-weight: normal; }

.membre .form {
  padding: 5%; }
  .membre .form .btn {
    min-width: 200px;
    border-radius: 6px; }
  .membre .form .link-more {
    font-weight: normal;
    text-decoration: underline;
    color: #000; }

.membre .form-inner {
  max-width: 900px;
  margin: 0 auto; }

.membre-profil-form .membre-photo .circle {
  background-color: transparent;
  border-color: #A5A1A1; }
  .membre-profil-form .membre-photo .circle img {
    filter: contrast(0);
    transition: filter .3s; }
  .membre-profil-form .membre-photo .circle:hover {
    background-color: #DF3388; }
    .membre-profil-form .membre-photo .circle:hover img {
      filter: none; }

.membre-abonnements-table .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px; }

.membre-abonnements-table a:not(.btn) {
  color: #A5A1A1; }

.membre-abonnements .title-w-border {
  border-color: #DF3388; }

.membre-abonnements-item {
  display: block;
  cursor: pointer; }
  .membre-abonnements-item .title-big {
    font-family: "Arboria", Arial, sans-serif;
    font-weight: normal; }
  .membre-abonnements-item .btn,
  .membre-abonnements-item .form {
    border-radius: 15px; }
  .membre-abonnements-item .form {
    margin-top: 15px;
    line-height: 2;
    text-align: center;
    color: #000;
    padding: 5px 10px; }
  .membre-abonnements-item .form-radio {
    display: inline-block;
    padding-left: 0;
    margin-top: 15px; }
    .membre-abonnements-item .form-radio span {
      position: relative;
      border-color: #7C0E6F !important;
      left: auto;
      top: auto;
      -ms-transform: none;
      transform: none; }
      .membre-abonnements-item .form-radio span:after {
        background-color: #7C0E6F; }
  .membre-abonnements-item .btn-white-pink {
    max-width: 300px;
    margin: 0 auto 20px auto;
    border-radius: 50px; }
  .membre-abonnements-item .text-item.small {
    font-size: 1.1rem; }
  .membre-abonnements-item.active .btn-white-pink {
    opacity: .5; }

.membre-emplois-slider {
  margin-bottom: 50px; }
  .membre-emplois-slider .offres-advert-item {
    color: #000;
    padding: 20px 10px 10px 20px;
    margin-bottom: 10px;
    border: none; }
  .membre-emplois-slider .slick-prev,
  .membre-emplois-slider .slick-next {
    filter: none;
    top: 40%; }
    .membre-emplois-slider .slick-prev:hover,
    .membre-emplois-slider .slick-next:hover {
      filter: brightness(10); }
  .membre-emplois-slider .slick-prev {
    left: -25px; }
  .membre-emplois-slider .slick-next {
    right: -25px; }
  .membre-emplois-slider .slick-list {
    margin-left: -10px;
    margin-right: -10px; }
  .membre-emplois-slider .slick-slide {
    margin-right: 10px;
    margin-left: 10px; }
  .membre-emplois-slider .bottom-info .icon {
    margin-right: 10px; }
  .membre-emplois-slider .icon {
    transition: filter .3s; }
  .membre-emplois-slider a:hover .icon {
    filter: grayscale(1); }

.membre-emplois-info {
  margin-bottom: 50px; }
  .membre-emplois-info .bg-white {
    position: relative;
    padding: 50px 7%;
    color: #000;
    margin-bottom: 15px; }
  .membre-emplois-info .form-control {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 30px;
    left: 40px;
    top: 40px;
    width: auto;
    border-radius: 15px;
    font-weight: normal;
    color: #7C0E6F; }
  .membre-emplois-info .articles-work-title {
    text-align-last: left;
    margin: 0 0 30px !important; }
  .membre-emplois-info .col-md-8 {
    padding-left: 7%; }
  .membre-emplois-info h4 {
    text-transform: uppercase;
    font-weight: bold; }
  .membre-emplois-info .bottom-info .icon {
    margin-right: 10px; }

.membre-emplois-logo {
  display: block;
  margin: 0 auto 50px auto;
  max-height: 250px; }

.livre-image-big {
  object-fit: contain; }

.livre-product-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row; }

.livre-product-image {
  text-align: center; }
  .livre-product-image .icon {
    margin-top: 10px;
    transition: filter .3s; }
  .livre-product-image:hover .icon {
    filter: brightness(0); }

.livre-product-info .title-large {
  font-weight: bold;
  margin-bottom: 10px; }
  .livre-product-info .title-large .title-middle {
    display: block; }

.livre-product-info .text-item p {
  margin-bottom: 0 !important; }

.livre-product-buy {
  padding: 30px;
  color: #000; }
  .livre-product-buy .title-middle {
    font-weight: bold; }
  .livre-product-buy .icon {
    width: 30px;
    height: 30px;
    margin-right: 15px; }
  .livre-product-buy .btn {
    width: 100%;
    border-radius: 15px;
    margin-top: 5px; }
  .livre-product-buy .text-item {
    margin-bottom: 0; }
    .livre-product-buy .text-item:not(:last-child) {
      margin-bottom: 15px; }

.livre-slider-wrapper {
  padding: 0 17%; }
  .livre-slider-wrapper .title-large {
    color: #DF3388;
    margin-bottom: 30px;
    text-align: center; }

.livre-slider .slick-next,
.livre-slider .slick-prev {
  filter: none;
  top: 45%; }
  .livre-slider .slick-next:hover,
  .livre-slider .slick-prev:hover {
    filter: brightness(0); }

.livre-slider .slick-next {
  right: -25px; }

.livre-slider .slick-prev {
  left: -25px; }

.charte-about .section-title {
  text-align: left; }

.charte-about-photo {
  text-align: center;
  max-width: 100%;
  width: 300px;
  margin: 0 auto; }
  .charte-about-photo .title-small {
    margin-top: 10px;
    font-weight: bold; }

.charte-about-text {
  font-size: 1.875rem;
  font-style: italic;
  line-height: 2; }

.charte-about-image {
  display: block;
  width: 55%;
  margin: 15px auto; }

.charte-about-item {
  line-height: 1.6;
  font-size: 1.875rem;
  margin-bottom: 50px; }
  .charte-about-item h3 {
    font-weight: bold;
    text-transform: uppercase; }

.text-page {
  border-radius: 50px;
  background-color: #fff;
  padding: 50px; }
  .text-page .title-large {
    text-align: center;
    color: #A5A1A1;
    margin-bottom: 50px; }
  .text-page .text-item {
    font-size: 14px;
    columns: 2;
    column-gap: 40px; }
  .text-page h3,
  .text-page h4,
  .text-page h5,
  .text-page h6 {
    font-weight: bold;
    margin-bottom: 30px; }
  .text-page h3 {
    font-size: 1.125rem; }

.forfaits .title-large {
  text-align: center;
  color: #DF3388;
  margin-bottom: 50px; }

.forfaits .circle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: #DF3388;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  margin: 0 auto 10px; }

.forfaits-subscribe {
  overflow: visible; }
  .forfaits-subscribe .form-control {
    font-weight: normal; }
    .forfaits-subscribe .form-control:-ms-input-placeholder {
      font-weight: normal; }
    .forfaits-subscribe .form-control::placeholder {
      font-weight: normal; }
  .forfaits-subscribe .form-checkbox {
    font-style: normal;
    display: inline-block;
    text-align: left; }
    .forfaits-subscribe .form-checkbox span {
      top: 3px; }
  .forfaits-subscribe .form-radio {
    font-weight: normal; }
    .forfaits-subscribe .form-radio img {
      max-height: 55px; }
  .forfaits-subscribe .nice-select {
    color: #A5A1A1; }
  .forfaits-subscribe-item {
    max-width: 900px;
    margin: 0 auto; }
  .forfaits-subscribe-price {
    background-color: #963E8B;
    color: #fff; }
  .forfaits-subscribe .slick-prev,
  .forfaits-subscribe .slick-next {
    top: 50px;
    filter: none; }
    .forfaits-subscribe .slick-prev:hover,
    .forfaits-subscribe .slick-next:hover {
      filter: grayscale(1); }

.architectes-gallery-part {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 15px; }

.architectes-item {
  position: relative;
  height: 320px; }
  .architectes-item.big {
    height: calc(320px * 2 + 15px); }
  .architectes-item:nth-child(4) {
    grid-column: 1/3;
    grid-row: 2/4; }
  .architectes-item:nth-child(11) {
    grid-column: 2/4;
    grid-row: 5/7; }
  .architectes-item:nth-child(13) {
    grid-column: 1/3; }
  .architectes-item img {
    display: block; }
  .architectes-item-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(49, 49, 49, 0.7);
    color: #fff;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    transition: opacity .3s, visibility .3s; }
    .architectes-item-content p {
      margin-bottom: 0 !important; }
    .architectes-item-content .icon {
      margin-bottom: 15px; }
  .architectes-item:hover .architectes-item-content {
    visibility: visible;
    opacity: 1; }

.architectes-bottom {
  margin-bottom: 50px; }
  .architectes-bottom img {
    display: block;
    width: 100%;
    padding: 15px 0;
    background-color: #76A346; }

.chiffres-item {
  position: relative;
  height: 480px; }
  .chiffres-item .image-object {
    filter: brightness(0.7); }
  .chiffres-item-content {
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    padding: 50px 15px;
    color: #fff;
    text-align: center;
    font-family: "Geometos", Arial, sans-serif;
    font-size: 10rem; }
    .chiffres-item-content small {
      font-size: .55em; }
    .chiffres-item-content .title-middle {
      font-weight: bold;
      font-family: "AdorHairline", Arial, sans-serif; }

.sitemap-list {
  margin-bottom: 50px; }

.sitemap-item {
  border-radius: 15px;
  overflow: hidden;
  background-color: rgba(150, 62, 139, 0.4);
  list-style-type: none; }
  .sitemap-item li:first-child {
    border-bottom: 2px solid #fff; }
    .sitemap-item li:first-child a {
      font-size: 1.125em;
      color: #fff;
      font-weight: bold;
      padding-top: 15px; }
      .sitemap-item li:first-child a:hover {
        color: #000; }
  .sitemap-item li:last-child a {
    padding-bottom: 15px; }
  .sitemap-item li:not(:last-child):not(:first-child) {
    border-bottom: .5px solid #fff; }
  .sitemap-item a {
    display: block;
    color: #000;
    padding: 5px 20px;
    transition: .3s; }
    .sitemap-item a:hover {
      background-color: #EFEDED;
      text-shadow: 0 0 1px #000; }

.network .text-item {
  font-size: 1.5rem; }

.network-content {
  margin-bottom: 50px; }
  .network-content-title {
    font-size: 1.5rem;
    color: #7C0E6F;
    text-align: center;
    padding: 15px 0;
    margin-bottom: 0; }
  .network-content-image {
    position: relative; }
    .network-content-image .text-item {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 40px;
      background-color: #DF3388;
      color: #fff;
      margin-bottom: 0; }
  .network-content-text {
    background-color: #EFEDED;
    border-radius: 15px;
    padding: 40px 30px;
    margin-bottom: 0; }

.network-product {
  padding: 40px 50px;
  background: linear-gradient(to bottom, #963E8B, #4B1F46);
  color: #fff; }
  .network-product .title-large {
    margin-bottom: 20px; }
  .network-product .v-align-center {
    height: 100%; }
  .network-product-wrapper {
    margin-bottom: 50px; }
    .network-product-wrapper .title-small {
      color: #7C0E6F;
      margin-bottom: 50px;
      font-weight: bold; }

.webinaire-partners {
  text-align: center; }
  .webinaire-partners img {
    max-height: 100px;
    margin: 0 5px; }
  .webinaire-partners .text-item {
    margin-top: 20px; }

@media (min-width: 992px) {
  .header-menu-toggler {
    display: none; }
  .submenu-toggle-mobile {
    display: none; }
  .header-menu-item:hover .submenu {
    opacity: 1;
    visibility: visible; } }

@media (min-width: 1400px) {
  .container {
    max-width: 1340px; } }

@media (min-width: 1600px) {
  .container {
    max-width: 1540px; } }

@media (max-width: 1599px) {
  html {
    font-size: 15px; }
  .header {
    padding: 50px 0 30px; }
  .header-menu-item a {
    padding: 12px; }
  .header-menu-item a img {
    max-width: 25px; }
  .header-title {
    letter-spacing: 5px;
    font-size: 3rem; }
  .header-user a {
    width: 57px;
    height: 57px; }
  .promo-title {
    font-size: 4rem; }
  .title-mega {
    font-size: 3.2rem; }
  .promo-item-small .promo-title {
    font-size: 2.8rem; }
  .btn {
    min-width: 130px;
    padding: 8px 15px; }
  .btn-big {
    min-width: 200px;
    padding: 15px 20px; }
  .logo {
    padding: 20px 20px 20px 30px; }
  .fly-block-buttons {
    width: 60px; }
  .fly-block-buttons .btn {
    height: 60px;
    min-width: 250px;
    padding: 15px 20px; }
  .title-w-icon img,
  .icon-video img,
  .actualites-article-links a img {
    -ms-transform: scale(0.8);
    transform: scale(0.8); }
  .form-file img {
    -ms-transform: translateY(-50%) scale(0.8);
    transform: translateY(-50%) scale(0.8); }
  .articles-text,
  .articles-work-text,
  .emploi-about-text,
  .btn-big,
  .entreprises-single .contacts-item,
  .evenements-item-title,
  .tv-next-item {
    font-size: 1.2rem; }
  .title-large {
    font-size: 2.4rem; }
  .title-big {
    font-size: 2rem; }
  .title-middle,
  .footer-text span {
    font-size: 1.8rem; }
  .title-small,
  .promo-slider-content span,
  .interviews-slider-title,
  .expand-block-title,
  .promo-page .promo-item-small .promo-title,
  .charte-about-item,
  .charte-about-text {
    font-size: 1.5rem; }
  .entreprises-premium-title {
    font-size: 1.4rem; }
  .promo-slider-content span:after {
    top: 2rem; }
  .companies-slider-item a {
    height: 200px; }
  .form-control:not(textarea),
  .footer-form .btn {
    height: 50px; }
  .footer-form .btn {
    min-width: 150px;
    font-size: 1.1rem; }
  .footer {
    padding: 30px 0; }
  .articles-work-item img {
    max-height: 150px; }
  .section-title {
    margin-bottom: 40px; }
  .form-control,
  .form-radio,
  .form-checkbox,
  .form-file,
  .form-label {
    font-size: 1.1rem; }
  .form-group {
    margin-bottom: 30px; }
  .form-radio span,
  .form-checkbox span {
    width: 22px;
    height: 22px; }
  .form-radio,
  .form-checkbox,
  .form-file {
    padding-left: 35px; }
  .entreprises-featured-bottom img {
    max-height: 80px; }
  .text-item p:not(:last-child),
  .text-page h3,
  .text-page h4,
  .text-page h5,
  .text-page h6 {
    margin-bottom: 20px; }
  .tv-logo {
    max-width: 300px; }
  .membre-emplois-logo {
    max-height: 200px; }
  .charte-about-text {
    line-height: 1.8; }
  .charte-about-photo {
    width: 250px; }
  .form-info {
    padding: 30px 15px; }
  .entreprises-featured .entreprises-item-image {
    min-height: 350px; }
  .actualites-article-links a {
    margin-right: 20px; }
  .chiffres-item {
    height: 425px; }
  .chiffres-item-content {
    font-size: 8rem; }
  .sitemap-item a {
    padding: 5px 10px; }
  .network-content-image .text-item {
    padding: 30px; }
  .network-product {
    padding: 30px 40px; }
  .evenements-item.big img {
    height: 430px; } }

@media (max-width: 1399px) {
  html {
    font-size: 14px; }
  .header-title {
    letter-spacing: 3px; }
  .header-menu {
    padding: 0 10px; }
  .header-menu-item a {
    padding: 10px; }
  .header-user a {
    margin-left: 10px; }
  .title-large {
    font-size: 2.1rem; }
  .title-big {
    font-size: 1.8rem; }
  .title-medium,
  .footer-text span {
    font-size: 1.6rem; }
  .entreprises-premium-title,
  .expand-block-title,
  .entreprises-item.logo-w-title .title-bottom,
  .text-item {
    font-size: 1.2rem; }
  .promo-item-small .promo-title,
  .offres-entreprise .expand-block-title.big {
    font-size: 2.6rem; }
  .promo-title {
    font-size: 3.5rem; }
  .title-mega {
    font-size: 3rem; }
  .fly-block-buttons {
    width: 45px; }
  .fly-block-buttons .btn {
    height: 45px;
    min-width: 230px;
    padding: 12px 15px;
    font-size: 1.3rem; }
  .footer-text {
    margin-right: 15px; }
  .footer-form .btn {
    min-width: 125px; }
  .footer-logo {
    width: 150px; }
  .companies-slider-item a {
    height: 175px; }
  .projects-slider-item a {
    height: 200px; }
  .projects-slider .slick-list:before,
  .projects-slider .slick-list:after {
    top: 150px; }
  .projects-slider .slick-prev,
  .projects-slider .slick-next {
    top: 100px; }
  .slick-prev:before,
  .slick-next:before,
  .slick-next,
  .slick-prev {
    width: 15px;
    height: 25px; }
  .border-decor {
    border-width: 15px; }
  .articles-item-bg {
    min-height: 400px; }
  .entreprises-featured-inner {
    padding: 20px; }
  .entreprises-featured-header {
    padding: 15px 30px; }
  .text-item p:not(:last-child),
  .text-page h3,
  .text-page h4,
  .text-page h5,
  .text-page h6 {
    margin-bottom: 15px; }
  .entreprises-single-about .title-small {
    margin-bottom: 20px; }
  .entreprises-single-logo {
    max-height: 200px; }
  .text-item,
  .charte-about-item,
  .charte-about-text {
    line-height: 1.4; }
  .evenements-item-title img {
    width: 25px; }
  .evenements-item-title {
    padding-left: 40px; }
  .tv-next-title {
    height: 100px; }
  .tv-single-item .entreprises-single-views img {
    height: 30px; }
  .offres-entreprise .form-control {
    max-width: 250px; }
  .offres-entreprise-item .expand-block-title {
    min-height: 110px; }
  .membre-photo .circle {
    width: 150px;
    height: 150px; }
  .text-page {
    padding: 40px; }
  .forfaits .circle {
    width: 30px;
    height: 30px;
    font-size: 1.25rem; }
  .form-info {
    padding: 15px; }
  .actualites-item .image-object {
    height: 300px; }
  .chiffres-item {
    height: 360px; }
    .chiffres-item .title-middle {
      font-size: 1.6rem; }
  .network-product-wrapper .title-small {
    margin-bottom: 30px; }
  .evenements-item.big img {
    height: 375px; } }

@media (max-width: 1199px) {
  html {
    font-size: 13px; }
  .header-title {
    font-size: 2.6rem;
    letter-spacing: 1px;
    -ms-flex: none;
    flex: none;
    margin-right: 30px; }
  .header-user {
    position: absolute;
    top: -60px;
    right: 0; }
  .header-menu-wrapper nav,
  .footer-form .form-control {
    width: 100%; }
  .footer-form .form-control {
    float: none; }
  .footer-form .btn {
    float: none;
    display: block;
    width: auto;
    margin: 10px auto 0 auto; }
  .form-control:not(textarea),
  .footer-form .btn {
    height: 40px; }
  .footer-text {
    font-size: 1rem; }
  .companies-slider-item a {
    height: 150px; }
  .btn {
    min-width: 100px; }
  .btn-big {
    min-width: 150px; }
  .promo-page .promo-right-slider .promo-item-small .promo-content {
    left: 40px;
    max-width: 75%; }
  .offres-advert-item {
    padding: 20px 20px 5px 20px; }
  .offres-news .articles-item {
    margin-bottom: 30px; }
  .border-radius {
    border-radius: 30px; }
  .expand-block-title:after {
    width: 15px;
    height: 10px; }
  .expand-block-title,
  .expand-block-content {
    padding: 10px 10px 20px;
    border-radius: 15px; }
  .entreprises-premium .prompt {
    font-size: 12px; }
  .evenements-buttons {
    margin-top: 15px; }
    .evenements-buttons .btn {
      margin: 0 10px 5px 0; }
  .tv .interviews-slider .slick-next,
  .tv .tv-slider .slick-next {
    right: -15px; }
  .membre-emplois-slider .slick-next {
    right: -20px; }
  .membre-emplois-slider .slick-prev {
    left: -20px; }
  .tv .interviews-slider .slick-prev,
  .tv .tv-slider .slick-prev {
    left: -15px; }
  .offres-entreprise-item .expand-block-title {
    min-height: 90px;
    border-radius: 2rem; }
  .entreprises-premium .btn {
    min-width: 150px; }
  .form-password-toggle .icon {
    max-width: 30px; }
  .header-lang img {
    width: 30px;
    height: 30px; }
  .header-lang a {
    margin-right: 5px; }
  .header-user a {
    width: 35px;
    height: 35px; }
  .header-lang a img {
    width: 30px;
    height: 30px; }
  .entreprises-featured-header .icon:first-child,
  .entreprises-featured-header .icon:last-child,
  .entreprises-item-header .icon:first-child,
  .entreprises-item-header .icon:last-child {
    margin-right: 10px; }
  .livre-product-buy {
    padding: 15px 20px; }
  .charte-about-photo {
    width: 200px; }
  .architectes-item {
    height: 200px; }
  .architectes-item.big {
    height: calc(200px * 2 + 15px); }
  .architectes-item-content .icon {
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    margin-bottom: 5px; }
  .actualites-item .image-object {
    height: 250px; }
  .chiffres-item {
    height: 300px; }
    .chiffres-item .title-middle {
      font-size: 1.5rem; }
  .chiffres-item-content {
    font-size: 7rem; }
  .evenements-item.big img {
    height: 330px; } }

@media (max-width: 991px) {
  .header {
    padding: 30px 0 20px; }
  .header-title {
    margin-right: 0; }
  .header-user {
    position: relative;
    top: auto;
    right: auto;
    margin-right: 30px; }
  .header-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    background-color: #fff;
    border: none;
    border-radius: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility .3s;
    z-index: -1; }
    .header-menu.active {
      opacity: 1;
      visibility: visible;
      z-index: 100; }
  .header.scroll .header-menu-toggler {
    position: fixed;
    right: 30px;
    top: 30px;
    z-index: 110; }
  body.bg-black .header-menu {
    background-color: #000; }
  .promo-item-small {
    width: 50%;
    height: calc(100vw*.44);
    float: left; }
  .promo-slider-item {
    height: calc(100vw*.5); }
  .fly-block {
    top: 200px; }
  .fly-block-buttons {
    width: 40px; }
  .fly-block-buttons .btn {
    height: 40px;
    padding: 10px 15px;
    min-width: 180px;
    font-size: 1.125rem; }
  .promo-title,
  .title-mega {
    font-size: 2.5rem; }
  .title-large {
    font-size: 1.8rem; }
  .title-big {
    font-size: 1.6rem; }
  .title-middle {
    font-size: 1.5rem; }
  .footer-text span,
  .title-small,
  .promo-slider-content span,
  .interviews-slider-title {
    font-size: 1.4rem; }
  .articles .promo-slider-item {
    height: auto;
    -ms-flex: auto;
    flex: auto; }
  .title-w-icon img,
  .icon-video img {
    -ms-transform: scale(0.7);
    transform: scale(0.7); }
  .form-file img {
    -ms-transform: translateY(-50%) scale(0.7);
    transform: translateY(-50%) scale(0.7); }
  .articles-work {
    margin-bottom: 50px; }
  .articles-aside-image,
  .articles-aside .image-w-link {
    display: block;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
  .footer-form {
    -ms-flex: 1;
    flex: 1;
    text-align: center; }
  .articles-list-wrapper.border-right-abs:after {
    content: none; }
  .articles-aside-logo img {
    display: block;
    margin-left: auto; }
  .header-menu {
    overflow-y: scroll; }
  .header-menu-item {
    display: block;
    text-align: center;
    width: 100%; }
  .header-menu .submenu {
    width: 100%; }
  .logo {
    top: 10%;
    padding: 10px;
    width: 15%; }
  .promo-page .promo-item-small {
    width: 100%; }
  .offres-promo-item {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    height: auto; }
  .articles-image,
  .articles-aside-image {
    height: auto !important; }
  .video-item {
    height: 300px; }
  .offres-promo-item {
    height: calc(100vw * .4); }
  .offres-news .articles-aside {
    text-align: center; }
  .articles-text {
    font-size: 14px; }
  .form-radio span,
  .form-checkbox span {
    width: 20px;
    height: 20px; }
  .form-radio,
  .form-checkbox,
  .form-file {
    padding-left: 30px; }
  .form-group {
    margin-bottom: 20px; }
  .actualites .border-right-abs:after {
    content: none; }
  .actualites-feed-item {
    margin-bottom: 30px; }
  .actualites-feed-text {
    font-size: 14px; }
  .entreprises-featured {
    margin-bottom: 30px; }
  .entreprises-search-form .text-center .btn {
    min-width: 225px; }
  .promo-text {
    font-size: 1rem;
    line-height: 1.1; }
  .entreprises-single .header-social {
    margin-top: 30px;
    text-align: center; }
  .evenements .promo-item-small:first-child,
  .evenements .promo-item-small:last-child {
    height: auto;
    width: 100%;
    margin-bottom: 10px; }
  .evenements .promo-item-big img,
  .evenements .promo-item img {
    margin: 0 auto; }
  .evenements .promo-item {
    margin-bottom: 10px; }
  .evenements .promo-item img {
    width: auto; }
  .evenements .video-item .video-item-content .date,
  .evenements .video-item .video-item-content .big-title {
    margin-bottom: 15px; }
  .offres-entreprise-table td .form-control {
    height: 30px;
    font-size: 1.5rem; }
  .offres-entreprise .expand-block-title.bg-l-pink {
    font-size: 2rem;
    border-radius: 20px; }
  .offres-entreprise-table th:not(:first-child),
  .offres-entreprise-table td:not(:first-child) {
    border-left-width: 10px; }
  .offres-entreprise-table td .btn {
    width: 115px; }
  .offres-entreprise-item .expand-block,
  .offres-entreprise-item.mb-30 {
    margin-bottom: 10px !important; }
  .offres-entreprise-item .text-item {
    margin-bottom: 20px; }
  .tv .evenements-buttons {
    margin-left: auto; }
  .membre-photo-title .btn {
    position: relative;
    top: auto;
    right: auto;
    -ms-transform: none;
    transform: none;
    display: block;
    margin: 15px auto;
    width: 180px; }
  .membre-photo-title {
    padding: 0; }
  .header-social:not(li):not(.mt-20):not(.social-block) {
    display: none; }
  .header-menu .header-social {
    display: block;
    margin-top: 15px; }
  .header-lang {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1; }
  .header-title {
    width: 100%; }
  .membre-emplois-info .col-md-8 {
    padding-left: 15px; }
  img.icon {
    -ms-transform: scale(0.8);
    transform: scale(0.8); }
  .membre-emplois-logo {
    max-height: 150px;
    margin-bottom: 30px; }
  .membre-emplois-info .form-control {
    position: inherit;
    left: auto;
    top: auto;
    max-width: 170px; }
  .membre-emplois-info .bg-white {
    padding: 30px; }
  .livre-image-big {
    margin-bottom: 15px; }
  .livre-product-buy {
    max-width: 400px;
    margin: 30px auto 0; }
  .architectes-gallery-part {
    grid-template-columns: 1fr 1fr; }
  .architectes-item:nth-child(4) {
    grid-row: 2/3; }
  .architectes-item:nth-child(11) {
    grid-column: 1/3;
    grid-row: 6/7; }
  .architectes-item:nth-child(13) {
    grid-column: auto; }
  .entreprises-single .text-item {
    margin-top: 30px; }
  .entreprises-item-logo {
    padding: 5px;
    height: 100px; }
  .network-content-image {
    margin-bottom: 15px; }
  .network-product {
    padding: 20px 30px; }
  .charte-about-image {
    width: 450px; }
  .evenements-item.big img {
    height: auto; } }

@media (max-width: 767px) {
  .fly-block {
    top: 140px; }
  .promo-content {
    left: 30px;
    bottom: 30px; }
  .promo-item-small .promo-content {
    left: 30px;
    top: 30px;
    max-width: 80%; }
  .promo-page .promo-item-big .promo-content {
    left: 120px; }
  .promo-page .promo-item-small .promo-content {
    left: 30px;
    max-width: 80%; }
  .articles-work [class*="col"] {
    margin-bottom: 15px; }
  .footer-logo {
    margin: 0 auto; }
  .footer-text {
    text-align: center;
    margin: 15px auto; }
  .articles-work-item,
  .actualites-feed .image-w-link,
  .actualites-list .articles-item,
  .entreprises-item,
  .entreprises-single-about .text-item:not(.text-item-bg) img,
  .actualites-article .text-item img,
  .actualites-item .image-object,
  .chiffres-item {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
  .logo {
    top: 30px; }
  .promo-text {
    font-size: 14px; }
  .emploi-about .border-right-abs:after {
    content: none; }
  .icon-video {
    left: 20px !important;
    top: 20px !important; }
  .video-item-content {
    left: 20px;
    bottom: 20px; }
  .actualites-feed-item {
    text-align: center; }
  .actualites-feed .title-w-icon span {
    margin: 0; }
  .entreprises-single-properties .btn {
    margin: 0 10px 10px 0; }
  .entreprises-single-logo {
    margin: 0 auto 30px auto; }
  .entreprises-single-properties {
    text-align: center; }
  .evenements .promo-item {
    height: auto; }
  .evenements .promo-item {
    height: auto; }
  .evenements .video-item {
    height: 300px; }
  .evenements .video-item .btn {
    bottom: 15px;
    right: 15px; }
  .tv-logo {
    max-width: 200px; }
  .actualites-article .text-item {
    columns: 1; }
  .offres-entreprise-table .expand-block-title,
  .offres-entreprise-table td .form-control {
    max-width: 100%; }
  .membre-photo:not(.membre-photo-empty) .circle {
    margin-top: 30px;
    margin-bottom: 30px; }
  .footer-inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .footer-menu-wrapper {
    width: 100%;
    margin-bottom: 20px; }
  .footer-form-wrapper {
    width: 100%; }
  .membre-emplois-info .form-control {
    padding: 5px 10px;
    margin: 0 auto 15px auto; }
  .livre-product-image:not(.gallery-w-popup) {
    max-width: 400px;
    margin: 0 auto 30px auto; }
  .livre-product-info {
    text-align: center; }
  .livre-slider-wrapper {
    padding: 0 30px; }
  .text-page .text-item {
    columns: 1; }
  .text-page {
    padding: 30px; }
  .entreprises-featured .entreprises-item-image {
    margin-top: 30px; }
  .actualites-item .image-object {
    height: 300px; }
  .chiffres-item {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
    .chiffres-item .title-middle {
      font-size: 1.3rem; }
  .sitemap [class*="col"].mb-50,
  .sitemap-list {
    margin-bottom: 30px !important; }
  .network-content-image .text-item {
    padding: 15px; }
  .network-content-text,
  .network-product {
    padding: 20px; }
  .network-product-image {
    margin-bottom: 15px; }
  .charte-about .section-title {
    text-align: center; } }

@media (max-width: 575px) {
  .promo-item-small {
    width: 100%;
    height: calc(100vw*.6); }
  .promo-right {
    display: block; }
  .promo-item-small .promo-content {
    top: auto;
    bottom: 30px; }
  .header-title {
    font-size: 2rem; }
  .promo-title,
  .title-mega {
    font-size: 2.5rem; }
  .promo-item-small .promo-title,
  .title-large {
    font-size: 2rem; }
  .btn {
    font-size: 14px;
    min-width: 100px; }
  .btn-big {
    min-width: 150px;
    padding: 12px 15px; }
  .articles-image,
  .articles-aside-image,
  .articles-aside .image-w-link,
  .articles-work-item {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; }
  .articles-image {
    display: block; }
  .companies-slider {
    margin: 0 -5px; }
  .companies-slider-item {
    padding: 0 5px; }
  .section {
    margin-bottom: 50px; }
  .title-middle {
    font-size: 1.4rem; }
  .footer-text span,
  .title-small,
  .promo-slider-content span,
  .interviews-slider-title,
  .charte-about-text,
  .charte-about-item {
    font-size: 1.2rem; }
  .title-w-icon img,
  .icon-video img {
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  .form-file img {
    -ms-transform: translateY(-50%) scale(0.6);
    transform: translateY(-50%) scale(0.6); }
  .fly-block .fly-block-buttons,
  .fly-block.scroll .fly-block-buttons {
    width: 28px; }
  .fly-block .fly-block-buttons .btn,
  .fly-block.scroll .fly-block-buttons .btn {
    height: 28px;
    padding: 7px 10px 5px;
    min-width: 150px;
    font-size: 14px; }
  .container {
    padding-right: 30px; }
  .title-large {
    text-align: center; }
  .promo-content,
  .promo-item-small .promo-content {
    left: 15px;
    bottom: 15px;
    max-width: 85%; }
  .promo-page .promo-item-small .promo-content {
    left: 15px;
    max-width: 85%; }
  .border-decor {
    border-width: 10px;
    padding-left: 10px; }
  .articles-image {
    margin-bottom: 15px; }
  .border-right-abs:not([class*="col"]) {
    padding-right: 0; }
  .border-right-abs:after {
    content: none; }
  .promo-slider-content span:after {
    top: 1.65rem; }
  .promo-right-slider .slick-next {
    right: 30px; }
  .offres-promo-item,
  .articles .promo-slider-item,
  .video-item {
    min-height: 250px; }
  .offres-promo-content {
    right: auto;
    left: 30px;
    padding-right: 30px; }
  .offres-promo-content .title-large {
    text-align: left; }
  .offres-advert-item .articles-title span {
    font-size: 12px; }
  .articles-work-item.big,
  .articles-work-item.big .articles-work-title {
    text-align: center; }
  .actualites .video-item .articles-title span {
    font-size: 1em; }
  .entreprises-search-form .text-center .btn {
    width: 100%; }
  .entreprises-search-form .text-center .btn:last-child {
    margin-left: 0;
    margin-top: 10px; }
  .entreprises-featured-bottom {
    padding: 0; }
  .entreprises-featured .title-large {
    margin-top: 15px; }
  .entreprises-premium .btn {
    width: 100%; }
  .entreprises-premium {
    padding: 15px 5%; }
  .tv .interviews-slider .slick-next,
  .tv .tv-slider .slick-next,
  .membre-emplois-slider .slick-next,
  .livre-slider .slick-next {
    right: 10px; }
  .tv .interviews-slider .slick-prev,
  .tv .tv-slider .slick-prev,
  .membre-emplois-slider .slick-prev,
  .livre-slider .slick-prev {
    left: 10px; }
  .tv-single-item .entreprises-single-views img {
    height: 20px; }
  .membre-photo .circle {
    width: 130px;
    height: 130px; }
  .membre-photo .circle {
    margin-bottom: 15px; }
  .membre-menu {
    margin-bottom: 50px; }
  .membre-emplois-info .bg-white {
    padding: 20px; }
  .livre-slider-wrapper {
    padding: 0; }
  .text-page {
    padding: 20px; }
  .forfaits-subscribe .form-radio img {
    max-height: 35px; }
  .form-radio.d-i-block {
    margin-right: 20px; }
    .form-radio.d-i-block:last-child {
      margin-right: 0; }
  .input-w-icon .form-control {
    padding-left: 40px; }
  .input-w-icon .icon {
    left: 20px; }
  .promo-page .logo,
  .logo {
    top: 15px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .promo-page .promo-item-big .promo-content {
    top: 80px;
    left: 15px;
    max-width: 85%; }
  .architectes-gallery-part {
    grid-template-columns: 1fr; }
  .architectes-item,
  .architectes-item.big {
    grid-row: auto !important;
    grid-column: auto !important;
    height: 250px; } }

@media (max-width: 480px) {
  .logo {
    padding: 5px; }
  .promo-item-big {
    height: auto; }
  .header-title {
    font-size: 20px; }
  .header-user {
    margin-right: 15px; }
  .title-w-icon img {
    margin-right: 5px; }
  .articles-image,
  .articles-aside-image,
  .articles-aside .image-w-link,
  .articles-work-item {
    max-width: 100%; }
  .entreprises-single .contacts-item-icon {
    display: block;
    margin: 0 auto;
    width: 25px; }
  .entreprises-single .contacts-item {
    padding-left: 40px; }
  .entreprises-single .contacts-item,
  .text-item {
    font-size: 14px; }
  .entreprises-single .contacts-item {
    min-height: 25px; } }

@media (max-width: 370px) {
  .header-social a {
    max-width: 25px; }
  .header-user a {
    margin-left: 5px;
    width: 25px;
    height: 25px; }
  .logo {
    top: 15px; }
  .articles-item-bg {
    min-height: 350px; }
  .entreprises-single-views img {
    margin-right: 5px; } }

/*# sourceMappingURL=style.css.map */
