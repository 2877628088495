//min-width queries

@media (min-width: 992px) {
	.header-menu-toggler {
		display: none;
	}

	.submenu-toggle-mobile {
		display: none;
	}

	.header-menu-item:hover {

		.submenu {
			opacity: 1;
			visibility: visible;
		}
	}
}

@media (min-width: 1400px) {
	.container {
		max-width: 1340px;
	}
}

@media (min-width: 1600px) {
	.container {
		max-width: 1540px;
	}
}

//max-width queries

@media (max-width: 1599px) {

	html {
		font-size: 15px;
	}

	.header {
		padding: 50px 0 30px;
	}

	.header-menu-item a {
		padding: 12px;
	}

	.header-menu-item a img {
		max-width: 25px;
	}

	.header-title {
		letter-spacing: 5px;
		font-size: 3rem;
	}

	.header-user a {
		width: 57px;
		height: 57px;
	}

	.promo-title {
		font-size: 4rem;
	}

	.title-mega {
		font-size: 3.2rem;
	}

	.promo-item-small .promo-title {
		font-size: 2.8rem;
	}

	.btn {
		min-width: 130px;
		padding: 8px 15px;
	}

	.btn-big {
		min-width: 200px;
		padding: 15px 20px;
	}

	.logo {
		padding: 20px 20px 20px 30px;
	}

	.fly-block-buttons {
		width: 60px;
	}

	.fly-block-buttons .btn {
		height: 60px;
		min-width: 250px;
		padding: 15px 20px;
	}

	.title-w-icon img,
	.icon-video img,
	.actualites-article-links a img {
		transform: scale(.8);
	}

	.form-file img {
		transform: translateY(-50%) scale(.8);
	}

	.articles-text,
	.articles-work-text,
	.emploi-about-text,
	.btn-big,
	.entreprises-single .contacts-item,
	.evenements-item-title,
	.tv-next-item {
		font-size: 1.2rem;
	}

	.title-large {
		font-size: 2.4rem;
	}

	.title-big {
		font-size: 2rem;
	}

	.title-middle,
	.footer-text span {
		font-size: 1.8rem;
	}

	.title-small,
	.promo-slider-content span,
	.interviews-slider-title,
	.expand-block-title,
	.promo-page .promo-item-small .promo-title,
	.charte-about-item,
	.charte-about-text {
		font-size: 1.5rem;
	}

	.entreprises-premium-title {
		font-size: 1.4rem;
	}

	.promo-slider-content span:after {
		top: 2rem;
	}

	.companies-slider-item a {
		height: 200px;
	}

	.form-control:not(textarea),
	.footer-form .btn {
		height: 50px;
	}

	.footer-form .btn {
		min-width: 150px;
		font-size: 1.1rem;
	}

	.footer {
		padding: 30px 0;
	}

	.articles-work-item img {
		max-height: 150px;
	}

	.section-title {
		margin-bottom: 40px;
	}

	.form-control,
	.form-radio,
	.form-checkbox,
	.form-file,
	.form-label {
		font-size: 1.1rem;
	}

	.form-group {
		margin-bottom: 30px;
	}

	.form-radio span,
	.form-checkbox span {
		width: 22px;
		height: 22px;
	}

	.form-radio,
	.form-checkbox,
	.form-file {
		padding-left: 35px;
	}

	.entreprises-featured-bottom img {
		max-height: 80px;
	}

	.text-item p:not(:last-child),
	.text-page h3,
	.text-page h4,
	.text-page h5,
	.text-page h6 {
		margin-bottom: 20px;
	}

	.tv-logo {
		max-width: 300px;
	}

	.membre-emplois-logo {
		max-height: 200px;
	}

	.charte-about-text {
		line-height: 1.8;
	}

	.charte-about-photo {
		width: 250px;
	}

	.form-info {
		padding: 30px 15px;
	}

	.entreprises-featured .entreprises-item-image {
		min-height: 350px;
	}

	.actualites-article-links a {
		margin-right: 20px;
	}

	.chiffres-item {
		height: 425px;
	}

	.chiffres-item-content {
		font-size: 8rem;
	}

	.sitemap-item a {
		padding: 5px 10px;
	}

	.network-content-image .text-item {
		padding: 30px;
	}

	.network-product {
		padding: 30px 40px;
	}

	.evenements-item.big img {
		height: 430px;
	}

}

@media (max-width: 1399px) {
	html {
		font-size: 14px;
	}

	.header-title {
		letter-spacing: 3px;
	}

	.header-menu {
		padding: 0 10px;
	}

	.header-menu-item a {
		padding: 10px;
	}

	.header-user a {
		margin-left: 10px;
	}

	.title-large {
		font-size: 2.1rem;
	}

	.title-big {
		font-size: 1.8rem;
	}

	.title-medium,
	.footer-text span {
		font-size: 1.6rem;
	}

	.entreprises-premium-title,
	.expand-block-title,
	.entreprises-item.logo-w-title .title-bottom,
	.text-item {
		font-size: 1.2rem;
	}

	.promo-item-small .promo-title,
	.offres-entreprise .expand-block-title.big {
		font-size: 2.6rem;
	}

	.promo-title {
		font-size: 3.5rem;
	}

	.title-mega {
		font-size: 3rem;
	}

	.fly-block-buttons {
		width: 45px;
	}

	.fly-block-buttons .btn {
		height: 45px;
		min-width: 230px;
		padding: 12px 15px;
		font-size: 1.3rem;
	}

	.footer-text {
		margin-right: 15px;
	}

	.footer-form .btn {
		min-width: 125px;
	}

	.footer-logo {
		width: 150px;
	}

	.companies-slider-item a {
		height: 175px;
	}

	.projects-slider-item a {
		height: 200px;
	}

	.projects-slider .slick-list:before,
	.projects-slider .slick-list:after {
		top: 150px;
	}

	.projects-slider .slick-prev,
	.projects-slider .slick-next {
		top: 100px;
	}

	.slick-prev:before,
	.slick-next:before,
	.slick-next,
	.slick-prev {
		width: 15px;
		height: 25px;
	}

	.border-decor {
		border-width: 15px;
	}

	.articles-item-bg {
		min-height: 400px;
	}

	.entreprises-featured-inner {
		padding: 20px;
	}

	.entreprises-featured-header {
		padding: 15px 30px;
	}

	.text-item p:not(:last-child),
	.text-page h3,
	.text-page h4,
	.text-page h5,
	.text-page h6 {
		margin-bottom: 15px;
	}

	.entreprises-single-about .title-small {
		margin-bottom: 20px;
	}

	.entreprises-single-logo {
		max-height: 200px;
	}

	.text-item,
	.charte-about-item,
	.charte-about-text {
		line-height: 1.4;
	}

	.evenements-item-title img {
		width: 25px;
	}

	.evenements-item-title {
		padding-left: 40px;
	}

	.tv-next-title {
		height: 100px;
	}

	.tv-single-item .entreprises-single-views img {
		height: 30px;
	}

	.offres-entreprise .form-control {
		max-width: 250px;
	}

	.offres-entreprise-item .expand-block-title {
		min-height: 110px;
	}

	.membre-photo .circle {
		width: 150px;
		height: 150px;
	}

	.text-page {
		padding: 40px;
	}

	.forfaits .circle {
		width: 30px;
		height: 30px;
		@include fs(20);
	}

	.form-info {
		padding: 15px;
	}

	.actualites-item .image-object {
		height: 300px;
	}

	.chiffres-item {
		height: 360px;

		.title-middle {
			font-size: 1.6rem;
		}
	}

	.network-product-wrapper .title-small {
		margin-bottom: 30px;
	}

	.evenements-item.big img {
		height: 375px;
	}

}

@media (max-width: 1199px) {
	html {
		font-size: 13px;
	}

	.header-title {
		font-size: 2.6rem;
		letter-spacing: 1px;
		flex: none;
		margin-right: 30px;
	}

	.header-user {
		position: absolute;
		top: -60px;
		right: 0;
	}

	.header-menu-wrapper nav,
	.footer-form .form-control {
		width: 100%;
	}

	.footer-form .form-control {
		float: none;
	}

	.footer-form .btn {
		float: none;
		display: block;
		width: auto;
		margin: 10px auto 0 auto;
	}

	.form-control:not(textarea),
	.footer-form .btn {
		height: 40px;
	}

	.footer-text {
		font-size: 1rem;
	}

	.companies-slider-item a {
		height: 150px;
	}

	.btn {
		min-width: 100px;
	}

	.btn-big {
		min-width: 150px;
	}

	.promo-page .promo-right-slider .promo-item-small .promo-content {
		left: 40px;
		max-width: 75%;
	}

	.offres-advert-item {
		padding: 20px 20px 5px 20px;
	}

	.offres-news .articles-item {
		margin-bottom: 30px;
	}

	.border-radius {
		border-radius: 30px;
	}

	.expand-block-title:after {
		width: 15px;
		height: 10px;
	}

	.expand-block-title,
	.expand-block-content {
		padding: 10px 10px 20px;
		border-radius: 15px;
	}

	.entreprises-premium .prompt {
		font-size: 12px;
	}

	.evenements-buttons {
		margin-top: 15px;

		.btn {
			margin: 0 10px 5px 0;
		}
	}

	.tv .interviews-slider .slick-next,
	.tv .tv-slider .slick-next {
		right: -15px;
	}

	.membre-emplois-slider .slick-next {
		right: -20px;
	}

	.membre-emplois-slider .slick-prev {
		left: -20px;
	}

	.tv .interviews-slider .slick-prev,
	.tv .tv-slider .slick-prev {
		left: -15px;
	}

	.offres-entreprise-item .expand-block-title {
		min-height: 90px;
		border-radius: 2rem;
	}

	.entreprises-premium .btn {
		min-width: 150px;
	}

	.form-password-toggle .icon {
		max-width: 30px;
	}

	.header-lang img {
		width: 30px;
		height: 30px;
	}

	.header-lang a {
		margin-right: 5px;
	}

	.header-user a {
		width: 35px;
		height: 35px;
	}

	.header-lang a img {
		width: 30px;
		height: 30px;
	}

	.entreprises-featured-header .icon:first-child,
	.entreprises-featured-header .icon:last-child,
	.entreprises-item-header .icon:first-child,
	.entreprises-item-header .icon:last-child {
		margin-right: 10px;
	}

	.livre-product-buy {
		padding: 15px 20px;
	}

	.charte-about-photo {
		width: 200px;
	}

	.architectes-item {
		height: 200px;
	}

	.architectes-item.big {
		height: calc(200px * 2 + 15px);
	}

	.architectes-item-content .icon {
		transform: scale(.7);
		margin-bottom: 5px;
	}

	.actualites-item .image-object {
		height: 250px;
	}

	.chiffres-item {
		height: 300px;

		.title-middle {
			font-size: 1.5rem;
		}
	}

	.chiffres-item-content {
		font-size: 7rem;
	}

	.evenements-item.big img {
		height: 330px;
	}
}

@media (max-width: 991px) {
	.header {
		padding: 30px 0 20px;
	}

	.header-title {
		margin-right: 0;
	}

	.header-user {
		position: relative;
		top: auto;
		right: auto;
		margin-right: 30px;
	}

	.header-menu {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		font-size: 14px;
		background-color: #fff;
		border: none;
		border-radius: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s, visibility .3s;
		z-index: -1;


		&.active {
			opacity: 1;
			visibility: visible;
			z-index: 100;
		}
	}

	.header.scroll .header-menu-toggler {
		position: fixed;
		right: 30px;
		top: 30px;
		z-index: 110;
	}

	body.bg-black .header-menu {
		background-color: #000;
	}

	.promo-item-small {
		width: 50%;
		height: calc(100vw*.44);
		float: left;
	}

	.promo-slider-item {
		height: calc(100vw*.5);
	}

	.fly-block {
		top: 200px;
	}

	.fly-block-buttons {
		width: 40px;
	}

	.fly-block-buttons .btn {
		height: 40px;
		padding: 10px 15px;
		min-width: 180px;
		font-size: 1.125rem;
	}

	.promo-title,
	.title-mega {
		font-size: 2.5rem;
	}

	.title-large {
		font-size: 1.8rem;
	}

	.title-big {
		font-size: 1.6rem;
	}

	.title-middle {
		font-size: 1.5rem;
	}

	.footer-text span,
	.title-small,
	.promo-slider-content span,
	.interviews-slider-title {
		font-size: 1.4rem;
	}

	.articles .promo-slider-item {
		height: auto;
		flex: auto;
	}

	.title-w-icon img,
	.icon-video img {
		transform: scale(.7);
	}

	.form-file img {
		transform: translateY(-50%) scale(.7);
	}

	.articles-work {
		margin-bottom: 50px;
	}

	.articles-aside-image,
	.articles-aside .image-w-link {
		display: block;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	.footer-form {
		flex: 1;
		text-align: center;
	}

	.articles-list-wrapper.border-right-abs:after {
		content: none;
	}

	.articles-aside-logo img {
		display: block;
		margin-left: auto;
	}

	.header-menu {
		overflow-y: scroll;
	}

	.header-menu-item {
		display: block;
		text-align: center;
		width: 100%;
	}

	.header-menu .submenu {
		width: 100%;
	}

	.logo {
		top: 10%;
		padding: 10px;
		width: 15%;
	}

	.promo-page .promo-item-small {
		width: 100%;
	}

	.offres-promo-item {
		flex: 0 0 100%;
		height: auto;
	}

	.articles-image,
	.articles-aside-image {
		height: auto !important;
	}

	.video-item {
		height: 300px;
	}

	.offres-promo-item {
		height: calc(100vw * .4);
	}

	.offres-news .articles-aside {
		text-align: center;
	}

	.articles-text {
		font-size: 14px;
	}

	.form-radio span,
	.form-checkbox span {
		width: 20px;
		height: 20px;
	}

	.form-radio,
	.form-checkbox,
	.form-file {
		padding-left: 30px;
	}

	.form-group {
		margin-bottom: 20px;
	}

	.actualites .border-right-abs:after {
		content: none;
	}

	.actualites-feed-item {
		margin-bottom: 30px;
	}

	.actualites-feed-text {
		font-size: 14px;
	}

	.entreprises-featured {
		margin-bottom: 30px;
	}

	.entreprises-search-form .text-center .btn {
		min-width: 225px;
	}

	.promo-text {
		font-size: 1rem;
		line-height: 1.1;
	}

	.entreprises-single .header-social {
		margin-top: 30px;
		text-align: center;
	}

	.evenements .promo-item-small:first-child,
	.evenements .promo-item-small:last-child {
		height: auto;
		width: 100%;
		margin-bottom: 10px;
	}

	.evenements .promo-item-big img,
	.evenements .promo-item img {
		margin: 0 auto;
	}

	.evenements .promo-item {
		margin-bottom: 10px;
	}

	.evenements .promo-item img {
		width: auto;
	}

	.evenements .video-item .video-item-content .date,
	.evenements .video-item .video-item-content .big-title {
		margin-bottom: 15px;
	}

	.offres-entreprise-table td .form-control {
		height: 30px;
		font-size: 1.5rem;
	}

	.offres-entreprise .expand-block-title.bg-l-pink {
		font-size: 2rem;
		border-radius: 20px;
	}

	.offres-entreprise-table th:not(:first-child),
	.offres-entreprise-table td:not(:first-child) {
		border-left-width: 10px;
	}

	.offres-entreprise-table td .btn {
		width: 115px;
	}

	.offres-entreprise-item .expand-block,
	.offres-entreprise-item.mb-30 {
		margin-bottom: 10px !important;
	}

	.offres-entreprise-item .text-item {
		margin-bottom: 20px;
	}

	.tv .evenements-buttons {
		margin-left: auto;
	}

	.membre-photo-title .btn {
		position: relative;
		top: auto;
		right: auto;
		transform: none;
		display: block;
		margin: 15px auto;
		width: 180px;
	}

	.membre-photo-title {
		padding: 0;
	}

	.header-social:not(li):not(.mt-20):not(.social-block) {
		display: none;
	}

	.header-menu .header-social {
		display: block;
		margin-top: 15px;
	}

	.header-lang {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 1;
	}

	.header-title {
		width: 100%;
	}

	.membre-emplois-info .col-md-8 {
		padding-left: 15px;
	}

	img.icon {
		transform: scale(.8);
	}

	.membre-emplois-logo {
		max-height: 150px;
		margin-bottom: 30px;
	}

	.membre-emplois-info .form-control {
		position: inherit;
		left: auto;
		top: auto;
		max-width: 170px;
	}

	.membre-emplois-info .bg-white {
		padding: 30px;
	}

	.livre-image-big {
		margin-bottom: 15px;

	}

	.livre-product-buy {
		max-width: 400px;
		margin: 30px auto 0;
	}

	.architectes-gallery-part {
		grid-template-columns: 1fr 1fr;
	}

	.architectes-item:nth-child(4) {
		grid-row: 2/3;
	}

	.architectes-item:nth-child(11) {
		grid-column: 1/3;
		grid-row: 6/7;
	}

	.architectes-item:nth-child(13) {
		grid-column: auto;
	}

	.entreprises-single .text-item {
		margin-top: 30px;
	}

	.entreprises-item-logo {
		padding: 5px;
		height: 100px;
	}

	.network-content-image {
		margin-bottom: 15px;
	}

	.network-product {
		padding: 20px 30px;
	}

	.charte-about-image {
		width: 450px;
	}

	.evenements-item.big img {
		height: auto;
	}
}

@media (max-width: 767px) {
	.fly-block {
		top: 140px;
	}

	.promo-content {
		left: 30px;
		bottom: 30px;
	}

	.promo-item-small .promo-content {
		left: 30px;
		top: 30px;
		max-width: 80%;
	}

	.promo-page .promo-item-big .promo-content {
		left: 120px;
	}

	.promo-page .promo-item-small .promo-content {
		left: 30px;
		max-width: 80%;
	}

	.articles-work [class*="col"] {
		margin-bottom: 15px;
	}

	.footer-logo {
		margin: 0 auto;
	}

	.footer-text {
		text-align: center;
		margin: 15px auto;
	}

	.articles-work-item,
	.actualites-feed .image-w-link,
	.actualites-list .articles-item,
	.entreprises-item,
	.entreprises-single-about .text-item:not(.text-item-bg) img,
	.actualites-article .text-item img,
	.actualites-item .image-object,
	.chiffres-item {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	.logo {
		top: 30px;
	}

	.promo-text {
		font-size: 14px;
	}

	.emploi-about .border-right-abs:after {
		content: none;
	}

	.icon-video {
		left: 20px !important;
		top: 20px !important;
	}

	.video-item-content {
		left: 20px;
		bottom: 20px;
	}

	.actualites-feed-item {
		text-align: center;
	}

	.actualites-feed .title-w-icon span {
		margin: 0;
	}

	.entreprises-single-properties .btn {
		margin: 0 10px 10px 0;
	}

	.entreprises-single-logo {
		margin: 0 auto 30px auto;
	}

	.entreprises-single-properties {
		text-align: center;
	}

	.evenements .promo-item {
		height: auto;
	}

	.evenements .promo-item {
		height: auto;
	}

	.evenements .video-item {
		height: 300px;
	}

	.evenements .video-item .btn {
		bottom: 15px;
		right: 15px;
	}

	.tv-logo {
		max-width: 200px;
	}

	.actualites-article .text-item {
		columns: 1;
	}

	.offres-entreprise-table .expand-block-title,
	.offres-entreprise-table td .form-control {
		max-width: 100%;
	}

	.membre-photo:not(.membre-photo-empty) .circle {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.footer-inner {
		flex-wrap: wrap;
	}

	.footer-menu-wrapper {
		width: 100%;
		margin-bottom: 20px;
	}

	.footer-form-wrapper {
		width: 100%;
	}

	.membre-emplois-info .form-control {
		padding: 5px 10px;
		margin: 0 auto 15px auto;
	}

	.livre-product-image:not(.gallery-w-popup) {
		max-width: 400px;
		margin: 0 auto 30px auto;
	}

	.livre-product-info {
		text-align: center;
	}

	.livre-slider-wrapper {
		padding: 0 30px;
	}

	.text-page .text-item {
		columns: 1;
	}

	.text-page {
		padding: 30px;
	}

	.entreprises-featured .entreprises-item-image {
		margin-top: 30px;
	}

	.actualites-item .image-object {
		height: 300px;
	}

	.chiffres-item {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;

		.title-middle {
			font-size: 1.3rem;
		}
	}

	.sitemap [class*="col"].mb-50,
	.sitemap-list {
		margin-bottom: 30px !important;
	}

	.network-content-image .text-item {
		padding: 15px;
	}

	.network-content-text,
	.network-product {
		padding: 20px;
	}

	.network-product-image {
		margin-bottom: 15px;
	}

	.charte-about .section-title {
		text-align: center;
	}
}


@media (max-width: 575px) {
	.promo-item-small {
		width: 100%;
		height: calc(100vw*.6);
	}

	.promo-right {
		display: block;
	}

	.promo-item-small .promo-content {
		top: auto;
		bottom: 30px;
	}

	.header-title {
		font-size: 2rem;
	}

	.promo-title,
	.title-mega {
		font-size: 2.5rem;
	}

	.promo-item-small .promo-title,
	.title-large {
		font-size: 2rem;
	}

	.btn {
		font-size: 14px;
		min-width: 100px;
	}

	.btn-big {
		min-width: 150px;
		padding: 12px 15px;
	}

	.articles-image,
	.articles-aside-image,
	.articles-aside .image-w-link,
	.articles-work-item {
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
	}

	.articles-image {
		display: block;
	}

	.companies-slider {
		margin: 0 -5px;
	}

	.companies-slider-item {
		padding: 0 5px;
	}

	.section {
		margin-bottom: 50px;
	}

	.title-middle {
		font-size: 1.4rem;
	}

	.footer-text span,
	.title-small,
	.promo-slider-content span,
	.interviews-slider-title,
	.charte-about-text,
	.charte-about-item {
		font-size: 1.2rem;
	}

	.title-w-icon img,
	.icon-video img {
		transform: scale(.6);
	}

	.form-file img {
		transform: translateY(-50%) scale(.6);
	}

	.fly-block .fly-block-buttons,
	.fly-block.scroll .fly-block-buttons {
		width: 28px;
	}

	.fly-block .fly-block-buttons .btn,
	.fly-block.scroll .fly-block-buttons .btn {
		height: 28px;
		padding: 7px 10px 5px;
		min-width: 150px;
		font-size: 14px;
	}

	.container {
		padding-right: 30px;
	}

	.title-large {
		text-align: center;
	}

	.promo-content,
	.promo-item-small .promo-content {
		left: 15px;
		bottom: 15px;
		max-width: 85%;
	}

	.promo-page .promo-item-small .promo-content {
		left: 15px;
		max-width: 85%;
	}

	.border-decor {
		border-width: 10px;
		padding-left: 10px;
	}

	.articles-image {
		margin-bottom: 15px;
	}

	.border-right-abs:not([class*="col"]) {
		padding-right: 0;
	}

	.border-right-abs:after {
		content: none;
	}

	.promo-slider-content span:after {
		top: 1.65rem;
	}

	.promo-right-slider {
		.slick-next {
			right: 30px;
		}
	}

	.offres-promo-item,
	.articles .promo-slider-item,
	.video-item {
		min-height: 250px;
	}

	.offres-promo-content {
		right: auto;
		left: 30px;
		padding-right: 30px;
	}

	.offres-promo-content .title-large {
		text-align: left;
	}

	.offres-advert-item .articles-title span {
		font-size: 12px;
	}

	.articles-work-item.big,
	.articles-work-item.big .articles-work-title {
		text-align: center;
	}

	.actualites .video-item .articles-title span {
		font-size: 1em;
	}

	.entreprises-search-form .text-center .btn {
		width: 100%;
	}

	.entreprises-search-form .text-center .btn:last-child {
		margin-left: 0;
		margin-top: 10px;
	}

	.entreprises-featured-bottom {
		padding: 0;
	}

	.entreprises-featured .title-large {
		margin-top: 15px;
	}

	.entreprises-premium .btn {
		width: 100%;
	}

	.entreprises-premium {
		padding: 15px 5%;
	}

	.tv .interviews-slider .slick-next,
	.tv .tv-slider .slick-next,
	.membre-emplois-slider .slick-next,
	.livre-slider .slick-next {
		right: 10px;
	}

	.tv .interviews-slider .slick-prev,
	.tv .tv-slider .slick-prev,
	.membre-emplois-slider .slick-prev,
	.livre-slider .slick-prev {
		left: 10px;
	}

	.tv-single-item .entreprises-single-views img {
		height: 20px;
	}

	.membre-photo .circle {
		width: 130px;
		height: 130px;
	}

	.membre-photo .circle {
		margin-bottom: 15px;
	}

	.membre-menu {
		margin-bottom: 50px;
	}

	.membre-emplois-info .bg-white {
		padding: 20px;
	}

	.livre-slider-wrapper {
		padding: 0;
	}

	.text-page {
		padding: 20px;
	}

	.forfaits-subscribe .form-radio img {
		max-height: 35px;
	}

	.form-radio.d-i-block {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	.input-w-icon .form-control {
		padding-left: 40px;
	}

	.input-w-icon .icon {
		left: 20px;
	}

	.promo-page .logo,
	.logo {
		top: 15px;
		left: 50%;
		transform: translateX(-50%);
	}

	.promo-page .promo-item-big .promo-content {
		top: 80px;
		left: 15px;
		max-width: 85%;
	}

	.architectes-gallery-part {
		grid-template-columns: 1fr;
	}

	.architectes-item,
	.architectes-item.big {
		grid-row: auto !important;
		grid-column: auto !important;
		height: 250px;
	}

}

@media (max-width: 480px) {
	.logo {
		padding: 5px;
	}

	.promo-item-big {
		height: auto;
	}

	.header-title {
		font-size: 20px;
	}

	.header-user {
		margin-right: 15px;
	}

	.title-w-icon img {
		margin-right: 5px;
	}

	.articles-image,
	.articles-aside-image,
	.articles-aside .image-w-link,
	.articles-work-item {
		max-width: 100%;
	}

	.entreprises-single .contacts-item-icon {
		display: block;
		margin: 0 auto;
		width: 25px;
	}

	.entreprises-single .contacts-item {
		padding-left: 40px;
	}

	.entreprises-single .contacts-item,
	.text-item {
		font-size: 14px;
	}

	.entreprises-single .contacts-item {
		min-height: 25px;
	}
}

@media (max-width: 370px) {
	.header-social a {
		max-width: 25px;
	}

	.header-user a {
		margin-left: 5px;
		width: 25px;
		height: 25px;
	}

	.logo {
		top: 15px;
	}

	.articles-item-bg {
		min-height: 350px;
	}

	.entreprises-single-views img {
		margin-right: 5px;
	}
}