/* main styles */

html {
	font-size: 16px;
}

body {
	font-family: $AdorHR;
	font-weight: normal;
	color: #000;
	background-color: #fff;
	letter-spacing: 0;
	line-height: 1.3;

	&.w-mobile-menu {
		overflow: hidden;
	}

	&.bg-black {
		background-color: #000;
		color: #fff;

		.header-menu,
		.header-user a {
			border-color: #fff;
		}

		.header-menu-item a {
			color: #fff;
		}

		.header a img {
			transition: filter .1s;
		}

		.header-social a img {
			filter: brightness(1.5);
		}

		.header-menu-item a:hover img {
			filter: brightness(0) saturate(100%) invert(34%) sepia(78%) saturate(3050%) hue-rotate(310deg) brightness(90%) contrast(94%);
		}

		.header-user a img,
		.header-menu-item a img,
		.header-social a:hover img,
		.actualites-social .header-social a img {
			filter: brightness(10);
		}

		.header-user a:hover,
		.header-user a.active {
			background-color: $pink;
			border-color: $pink;
		}

		.actualites-social .header-social a:hover img {
			filter: brightness(1.5);
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

a,
button {
	display: inline-block;
	-webkit-transform: translateZ(0);
	text-decoration: none;
}

.image-object {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.bg-image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.row-0 {
	margin-left: 0;
	margin-right: 0;

	[class*="col-"] {
		padding-left: 0;
		padding-right: 0;
	}
}

.row-5 {
	margin-left: -5px;
	margin-right: -5px;

	[class*="col-"] {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.row-10 {
	margin-left: -10px;
	margin-right: -10px;

	[class*="col-"] {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.row-25 {
	margin-left: -25px;
	margin-right: -25px;

	[class*="col-"] {
		padding-left: 25px;
		padding-right: 25px;
	}
}

.slick-prev:before {
	display: block;
	content: "";
	background-image: url(../images/icons/icon-arrow-left.svg);
	background-size: cover;
}

.slick-next:before {
	display: block;
	content: "";
	background-image: url(../images/icons/icon-arrow-right.svg);
	background-size: cover;
}

.slick-prev:before,
.slick-next:before,
.slick-next,
.slick-prev {
	width: 20px;
	height: 35px;
}

.slick-prev:before,
.slick-next:before {
	font-size: 30px;
	opacity: 1;
	transition: .3s;
}

.slick-next {
	right: 25px;
}

.slick-prev {
	left: 25px;
}

.slick-prev,
.slick-next {
	filter: brightness(10);
	transition: filter .3s;
	z-index: 1;

	&:hover {
		filter: brightness(1);
	}
}

.slick-dots {
	bottom: 15px;
}

.slick-dots li button:before {
	color: $pink;
	font-size: 10px;
	opacity: .7;
	font-size: 20px;
	text-shadow: 0 0 5px #fff;
	transition: .3s;
}

.slick-dots li.slick-active button:before {
	opacity: 1;
}

.form-control {
	border: none;
	border-radius: 0;
	width: 100%;
	padding: 5px 15px;
	background-color: #fff;
	color: #000;
	@include fs(20);
	font-family: $AdorHR;
	font-weight: bold;
	box-shadow: inset 0 3px 6px rgba(0, 0, 0, .25);
	transition: .3s;
}

.form-control:not(textarea) {
	height: 58px;
}

.form-control::placeholder {
	color: #A4A1A1;
	font-family: $AdorHR;
	font-weight: bold;
}

.form-control:focus {
	box-shadow: inset 0 0 0 2px $pink;
}

.form-group {
	display: block;
	margin-bottom: 40px;
}

.form-radio {
	position: relative;
	display: block;
	padding-left: 45px;
	@include fs(20);
	font-weight: bold;
	color: #A8A8A8;
	cursor: pointer;
	transition: .3s;

	input {
		display: none;
	}

	span {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		border: 3px solid #A8A8A8;

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 60%;
			height: 60%;
			border-radius: 50%;
			background-color: $pink;
			opacity: 0;
			transition: opacity .3s;
		}
	}

	input:checked+span {
		border-color: $pink;

		&:after {
			opacity: 1;
		}
	}

	&.d-i-block {
		margin-right: 30px;
	}
}

.form-file {
	position: relative;
	cursor: pointer;
	transition: color .3s;
	@include fs(20);
	padding-left: 45px;

	&:hover {
		color: #000;
	}

	input {
		display: none;
	}

	img {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&.d-i-block {
		margin-right: 30px;
	}
}

.form-checkbox {
	position: relative;
	display: block;
	padding-left: 45px;
	@include fs(22);
	font-style: italic;
	color: #707070;
	line-height: 26px;
	transition: .3s;

	input {
		display: none;
	}

	span {
		position: absolute;
		left: 0;
		top: 5px;
		display: block;
		width: 26px;
		height: 26px;
		border: 3px solid #A8A8A8;
		cursor: pointer;

		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 60%;
			height: 60%;
			background-color: $pink;
			opacity: 0;
			transition: opacity .3s;
		}
	}

	input:checked+span {
		border-color: $pink;

		&:after {
			opacity: 1;
		}
	}
}

.form-password {
	position: relative;

	&-toggle {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		filter: grayscale(1) brightness(3.5);
	}
}

.form-date {
	position: relative;

	&:after {
		position: absolute;
		content: "";
		width: 20px;
		height: 20px;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url('../images/icons/icon-calendar-2.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.input-w-icon {
	position: relative;

	.form-control {
		padding-left: 50px;
	}

	.icon {
		position: absolute;
		content: "";
		max-width: 20px;
		max-height: 20px;
		left: 30px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}
}

.form-label {
	display: inline-block;
	margin-bottom: 10px;
	@include fs(21);
}

.form-info {
	position: relative;
	padding: 40px 15px;
	text-align: center;
	font-weight: normal;
	line-height: 1.7;

	&:not(.h-100) {
		height: auto !important;
	}

	.close {
		position: absolute;
		right: 15px;
		top: 12px;
		transition: filter .3s;

		&:hover {
			filter: grayscale(1);
		}
	}
}

.clearfix {

	&:after {
		content: "";
		clear: both;
		display: table;
	}
}

.section {
	margin-bottom: 4.5rem;

	&-title {
		margin-bottom: 50px;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;

		.btn {
			margin-top: 15px;
			border-radius: 10px;
		}
	}
}

.iframe-wrapper {
	position: relative;
	padding-top: 56.25%;
	overflow: hidden;

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.menu-toggler {
	display: none;

	span {
		position: relative;
		display: block;
		width: 35px;
		height: 3px;
		background-color: #000;
		transform-origin: 4px 0px;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
	}

	span:not(:last-child) {
		margin-bottom: 8px;
	}

	span:first-child {
		transform-origin: 0% 0%;
	}

	&.active span:nth-child(2) {
		transform-origin: 0% 100%;
	}

	&.active span:first-child {
		opacity: 1;
		transform: rotate(45deg) translate(1px, -4px);
	}

	&.active span:nth-child(2) {
		opacity: 0;
		transform: rotate(0deg) scale(0.2, 0.2);
	}

	&.active span:nth-child(3) {
		transform: rotate(-45deg) translate(2px, -2px);
	}
}

.border-bottom {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid $gray;

	&.color-white {
		border-color: #fff;
	}
}

.border-right-abs {
	position: relative;
	padding-right: 15px;

	&:after {
		position: absolute;
		content: "";
		right: 0;
		top: 0;
		height: 100%;
		width: 1px;
		background-color: $gray;
	}
}

.border-radius {
	width: 100%;
	border-radius: 50px;
	overflow: hidden;
}

.link-more {
	color: $pink;
	font-weight: bold;
	font-size: 1rem;
	transition: color .3s;

	&:hover {
		color: #000;
	}

	&.color-white:hover {
		color: $pink  !important;
	}
}

.image-w-link {
	display: block;
	filter: brightness(.9);
	transition: filter .3s;

	img {
		display: block;
		width: 100%;
	}

	&:hover {
		filter: brightness(1);
	}
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.v-align-bottom {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.v-align-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: none !important;
}

.d-i-block {
	display: inline-block !important;
}

.container-big {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: 1920px;
}

.logo {
	position: absolute;
	top: 25%;
	left: 0;
	width: 20%;
	min-width: 100px;
	padding: 30px 30px 30px 50px;
	background-color: rgba(255, 255, 255, .7);
	box-shadow: 0 5px 6px rgba(0, 0, 0, .3);
	z-index: 10;
	transition: .2s;

	&:hover {
		background-color: #fff;
	}
}

.border-decor {
	border-left: 20px solid $pink;
	padding: 10px 0 10px 20px;
	font-weight: bold;
	color: #fff;

	&-violet {
		border-color: $violet;
	}

	&-l-violet {
		border-color: $l-violet;
	}

	&-white {
		border-color: #fff;
	}
}

.icon-video {
	position: absolute;
	color: $violet;
	z-index: 1;

	img {
		width: auto;
		display: inline-block;
	}

	&.white {
		color: #fff;
		@include fs(24);
		font-weight: bold;

		img {
			filter: brightness(100);
		}
	}
}

.nice-select:after {
	border-bottom: 3px solid #999;
	border-right: 3px solid #999;
	height: 10px;
	width: 10px;
}

.nice-select .list {
	border: 2px solid #df3388;
	border-radius: 0;
}

/* header */

.header {
	padding: 80px 0 50px;

	&-top {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-bottom: 25px;
	}

	&-lang {
		margin-right: 20px;

		a {
			display: inline-block;
			margin-right: 10px;

			img {
				border-radius: 50%;
				width: 35px;
				height: 35px;
				box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
			}

			&:hover img {
				filter: grayscale(1) !important;
			}
		}
	}

	&-title {
		flex: 1;
		text-align: center;
		@include fs(50);
		line-height: 1;
		font-weight: 300;
		letter-spacing: 7px;
		text-transform: uppercase;
	}

	&-menu {
		display: flex;
		border: 1px solid #9B9B9B;
		border-radius: 35px;
		padding: 0 15px;

		.header-social {
			display: none;
		}

		&-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		&-item {
			display: flex;
			align-items: center;
			position: relative;
			transition: background-color .3s;

			a {
				padding: 15px;
				color: #000;
				text-transform: uppercase;
				letter-spacing: 1px;
				transition: text-shadow .3s, color .3s;

				img {
					max-width: 28px;
				}

				&:hover {
					text-shadow: 0 0 1px $pink;
					color: $pink  !important;
				}
			}

			&.active {

				a {
					text-shadow: 0 0 1px $pink;
					color: $pink  !important;
				}
			}
		}

		.submenu {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			left: 0;
			top: 100%;
			padding: 10px 0;
			background-color: rgba(157, 155, 155, .75);
			box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
			z-index: 20;
			transition: opacity .3s, visibility .3s, background-color .3s;

			a {
				display: block;
				font-weight: 500;
				color: #fff;
				font-size: 14px;
				padding: 5px 15px;
				text-shadow: none;
				white-space: nowrap;
				transition: color .3s, background-color .3s;

				&:hover {
					background-color: #fff;
					color: $pink;
				}
			}

			&-toggle-mobile {

				position: absolute;
				left: 20%;
				top: 0;
				font-size: 25px;
				font-weight: bold;
			}
		}

		.submenu-toggle-mobile.active {
			transform: rotate(45deg);
		}

		.submenu-toggle-mobile.active+.submenu {
			display: block;
			position: inherit;
			top: auto;
			opacity: 1;
			visibility: visible;
		}
	}

	&-social {

		a {
			margin-right: 5px;
		}
	}

	&-user {
		margin-left: auto;
		display: flex;

		a {
			display: flex;
			margin-left: 15px;
			width: 65px;
			height: 65px;
			border: 1px solid #A5A1A1;
			border-radius: 50%;
			transition: border-color .3s, background-color .3s;

			img {
				margin: auto;
				max-width: 60%;
				transition: filter .3s;
			}

			&:hover,
			&.active {
				border-color: #000;

				img {
					filter: brightness(0);
				}
			}
		}
	}

	ul {
		list-style-type: none;
	}

	a {
		img {
			transition: filter .3s;
		}

		&:hover {

			img {
				filter: brightness(0);
			}
		}
	}
}

.header-menu-toggler {
	transition: .3s;
}

.header-menu-toggler span {
	position: relative;
	display: block;
	width: 35px;
	height: 3px;
	background-color: $violet;
	z-index: 1;
	transform-origin: 4px 0px;
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.header-menu-toggler span:not(:last-child) {
	margin-bottom: 10px;
}

.header-menu-toggler span:first-child {
	transform-origin: 0% 0%;
}

.header-menu-toggler.active span:nth-child(2) {
	transform-origin: 0% 100%;
}

.header-menu-toggler.active span:first-child {
	opacity: 1;
	transform: rotate(45deg) translate(3px, -4px);
}

.header-menu-toggler.active span:nth-child(2) {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
}

.header-menu-toggler.active span:nth-child(3) {
	transform: rotate(-45deg) translate(4px, -2px);
}

.header-menu-toggler.active {
	z-index: 110;
}

.fly-block {
	position: absolute;
	width: 100%;
	top: 300px;
	height: 0;
	transition: .3s;
	z-index: 10;

	&-buttons {
		display: flex;
		justify-content: flex-end;
		position: absolute;
		right: 0;
		top: 0;
		transform: rotate(-90deg);
		width: 80px;
		z-index: 10;

		.btn {
			height: 80px;
			min-width: 300px;
			@include fs(24);
			text-transform: uppercase;
			font-weight: bold;
			padding: 25px;
			border-radius: 10px 10px 0 0;
			transition: .2s;

			&:first-child {
				margin-right: 10px;
			}
		}
	}

	&.scroll {
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		transform: none;

		.fly-block-buttons {
			justify-content: center;
			width: 40px;

			.btn {
				height: 40px;
				padding: 10px 15px;
				min-width: 200px;
				@include fs(18);
			}
		}

	}
}

.page .fly-block {
	top: 310px;
}

.promo {
	position: relative;

	&-top {
		position: relative;
		margin-bottom: 15px;
	}

	&-item {
		position: relative;

		&-big {
			height: calc(100vw*0.6);
			max-height: 1200px;
			min-height: 300px;
		}

		&-small {
			height: 50%;
			max-height: 600px;
			min-height: 300px;
		}

		img {
			filter: brightness(.8);
		}

		.image-object {
			background-position: center 30%;
		}
	}

	&-content {
		position: absolute;
		right: 7%;
		bottom: 7%;
		font-weight: bold;
		max-width: 80%;
		color: #fff;

		.btn {
			margin-top: 10px;
		}
	}

	&-title {
		@include fs(70);
		margin-bottom: 20px;
		font-weight: bold;
		line-height: 1;
	}

	&-text {
		@include fs(20);
	}

	&-item-small {

		.promo-content {
			border-color: $l-violet;
			left: 13%;
			top: 10%;
			right: auto;
			bottom: auto;
			max-width: 65%;
		}

		.promo-title {
			@include fs(48);
		}

		.promo-text {
			@include fs(18);
		}
	}

	&-slider {

		&-item {
			position: relative;
			width: 50%;
			height: calc(100vw*0.25);
			max-height: 480px;

			img {
				filter: brightness(.6);
			}

			.icon-video {
				left: 70px;
				top: 50px;
			}
		}

		&-content {
			position: absolute;
			max-width: 80%;
			width: 600px;
			left: 50%;
			bottom: 15%;
			transform: translate(-50%);
			color: #fff;

			span {
				position: relative;
				display: block;
				@include fs(28);
				font-family: $Futura;
				font-weight: bold;
				text-align: center;

				&:after {
					position: absolute;
					content: "";
					height: 1px;
					width: 100%;
					left: 0;
					top: 2.5rem;
					background-color: #fff;
				}
			}

			.btn {
				margin-left: auto;
				margin-top: 10px;
			}
		}
	}

	.section-title {
		font-weight: normal;
	}
}

.interviews {

	&-slider {
		margin: 0 -10px;

		&-item {
			position: relative;
			padding: 0 10px;

			.icon-video {
				left: 40px;
				top: 35px;
			}
		}

		&-title {
			@include fs(26);
			font-weight: bold;
			margin-top: 10px;
			text-transform: uppercase;

			.lowercase {
				font-size: .9em;
			}

			.btn {
				font-size: 12px;
			}
		}

		.slick-prev,
		.slick-next {
			top: 40%;
		}
	}

}


.articles {

	&-list {
		margin-bottom: 20px;

		&-wrapper.border-right-abs:after {
			right: -15px;
		}
	}

	&-item {
		margin-bottom: 30px;

		&-bg {
			position: relative;
			padding: 15px 30px;
			height: 100%;
			min-height: 450px;

			.image-object {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
			}
		}

		&.align-justify {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}
	}

	&-image {
		height: calc(100vw * .25);
		max-height: 450px;
	}

	&-title,
	&-date {
		display: block;
		font-weight: bold;
		margin-bottom: 20px;
		color: #000;
	}

	&-title {

		&.title-w-border {
			padding-bottom: 5px;
			border-bottom: 0.5px solid #707070;
			margin-bottom: 30px;
		}
	}

	&-text {
		display: block;
		@include fs(21);
		color: #000;
		margin-bottom: 20px;
		line-height: 1.5;

		p:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	&-promo {
		display: flex;
		justify-content: flex-end;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.promo-slider-item {
		flex: 0 0 calc(100vw*0.7);
		max-width: 1200px;
	}

	&-work {

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
			border: 1px solid $gray;
			padding: 20px 20px 10px;

			img {
				max-height: 180px;
				max-width: 70%;
			}

			&.big {

				.articles-work-title {
					text-align: left;
				}

				.articles-work-text i {
					font-size: .9em;
					font-weight: 500;
				}

				span {
					display: inline-block;
				}
			}
		}

		&-title {
			margin-top: 15px;
			font-weight: bold;
			text-align: center;
			line-height: 1.2;
			margin-bottom: 10px !important;

			span {
				display: block;
				font-size: .85em;
				font-weight: normal;
			}
		}

		&-text {
			@include fs(24);
			margin-bottom: 10px;
		}

		.link-more {
			margin-left: auto;
		}
	}

	&-aside {
		padding-left: 15px;

		.title-w-icon {
			margin-bottom: 30px;
			color: #0C9BE6;
			@include fs(24);
		}

		&-item {

			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		&-logo {
			margin-bottom: 20px;

			img {
				width: 170px;
			}
		}
	}
}

.companies {

	&-title {
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&-slider {
		margin: 0 -15px;

		.slick-prev,
		.slick-next {
			top: 45%;
		}

		&-item {
			padding: 0 15px;

			a {
				display: flex;
				height: 230px;
				border: 1px solid $gray;
				border-radius: 50px;
				transition: border-color .3s;

				&:hover {
					border-color: $pink;
				}
			}

			img {
				margin: auto;
				max-width: 60%;
				max-height: 80%;
			}
		}

		.slick-prev,
		.slick-next {
			filter: brightness(1);

			&:hover {
				filter: brightness(0);
			}
		}
	}
}

.projects {

	&-title {
		margin-bottom: 30px;
		text-transform: uppercase;
	}

	&-slider {

		&-item {

			a {
				display: block;
				height: 250px;
			}
		}

		&-text {
			padding: 30px 10px 0 0;
			@include fs(18);
			font-style: italic;
		}

		.slick-next {
			right: 15px;
		}

		.slick-prev {
			left: 15px;
		}

		.slick-prev,
		.slick-next {
			top: 125px;
		}

		.slick-list {
			position: relative;
			border-radius: 50px 50px 0 0;

			&:before,
			&:after {
				position: absolute;
				content: "";
				width: 50px;
				height: 50px;
				top: 200px;
				left: 0;
				background-image: url(../images/mask-circle.png);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				z-index: 1;
			}

			&:after {
				position: absolute;
				content: "";
				left: auto;
				right: 0;
				transform: scale(-1, 1);
			}
		}
	}
}

.footer {
	background-color: #998EC0;
	padding: 50px 0;
	color: #fff;
	font-weight: bold;
	@include fs(18);

	&-inner {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 15px;
	}

	&-logo {
		width: 15%;
		margin-right: 15px;
	}

	&-text {
		text-align: center;
		margin-bottom: 15px;

		span {
			display: block;
			@include fs(26);
			text-transform: uppercase;
		}
	}

	&-form {

		* {
			vertical-align: middle;
		}

		.form-control {
			float: left;
			width: 48%;
			margin-right: 4%;
			@include fs(18);


			&::placeholder {
				text-align: center;
				@include fs(18);
			}
		}

		.btn {
			float: left;
			height: 58px;
			@include fs(18);
			font-weight: bold;
			width: 48%;
			border-width: 2px;
		}
	}

	&-menu {

		&-wrapper {
			text-align: center;
			margin-right: 15px;
		}

		&-buttons {
			list-style-type: none;
			margin: 15px 0 30px;

			li {
				display: inline-block;
			}

			.btn {
				padding: 7px;
				min-width: 0;
				margin: 0 3px 5px 0;
			}
		}

		li {
			display: inline-block;
			list-style-type: none;

			&:not(:last-child):after {
				display: inline-block;
				content: "-";
				margin-left: 5px;
				margin-right: 2px;
			}
		}

		a {
			color: #fff;
			transition: color .3s;

			&:hover {
				color: #000;
			}
		}
	}
}

.promo-page {

	.promo-item-small,
	.promo-item-big {
		height: calc(100vw*0.24);
		max-height: 600px;
		min-height: 300px;
	}

	.promo-item-big {

		.promo-content {
			left: 33%;
			top: 10%;
			bottom: auto;
			max-width: 80%;

			&.align-bottom {
				top: auto;
				bottom: 30px;
			}
		}
	}

	.promo-item-small {

		.promo-content {
			max-width: 70%;
			top: auto;
			left: 50px;
			bottom: 30px;
		}

		.promo-title {
			@include fs(28);
		}
	}

	.logo {
		top: 50px;
	}
}

.promo-bottom {

	[class*="col"] {

		&:nth-child(3),
		&:nth-child(4) {

			.promo-content {
				bottom: auto;
				top: 7%;
			}

		}
	}
}

.promo-right-slider {

	.slick-prev {
		left: 15px;
	}

	.slick-next {
		right: 15px;
	}
}

.emploi {


	&-about {

		.title-big {
			text-transform: none;
		}

		.articles-text {

			a {
				color: $pink;
				font-weight: bold;
				text-decoration: underline;
				transition: color .3s;

				&:hover {
					color: #000;
				}
			}
		}

		.title {
			text-align: center;
			margin-bottom: 50px;
			font-weight: bold;
		}
	}
}

.text-top {
	top: 30px !important;
	bottom: auto !important;
}

.text-bottom {
	bottom: 30px !important;
	top: auto !important;
}

.offres {

	.section-title {
		text-align: center;
		font-weight: bold;
		text-transform: uppercase;
	}

	.articles-work {

		[class*="col"] {
			margin-bottom: 30px;
		}
	}

	.articles-work-item {
		max-width: 100%;
	}

	&-advert {

		.section-title {
			text-transform: none;
		}

		&-list {
			margin-bottom: 30px;

			[class*="col"] {
				margin-bottom: 20px;
			}
		}

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border: 1px solid #707070;
			padding: 30px 30px 5px 30px;
			text-align: center;
			@include fs(24);
			height: 100%;

			.articles-title {

				span {
					display: block;
					font-size: .7em;
					margin-top: 5px;
				}
			}
		}
	}

	&-promo {
		display: flex;
		flex-wrap: wrap;

		&-item {
			position: relative;
			flex: 0 0 50%;
			height: calc(100vw * .2);
			max-height: 360px;

			img {
				filter: brightness(0.8);
			}

			&:last-child {
				.offres-promo-content {
					left: 30px;
				}
			}
		}

		&-content {
			position: absolute;
			top: 30px;
			right: 30px;
			color: #fff;
			width: 450px;
			max-width: 90%;

			.title-large {
				font-weight: bold;
			}

		}
	}

	&-news {

		.section-title {
			text-transform: none;
		}

		.articles-item {
			margin-bottom: 50px;

			&>.articles-title.title-w-border {
				font-weight: normal;
				margin-bottom: 30px;
			}
		}
	}

	&-entreprise {

		.section-title {
			font-weight: normal;
		}

		&-table {
			width: 100%;
			color: $violet;
			@include fs(18);
			line-height: 1;
			margin-bottom: 30px;

			tr,
			td {
				vertical-align: middle;
				height: 40px;
				border: 1px solid #fff;
				padding: 5px;
			}

			td {
				.form-control {
					height: 45px;
					margin: 30px auto;
					max-width: 80%;
					white-space: nowrap;
				}

				.btn {
					width: 150px;
					max-width: 100%;
					min-width: 0;
				}
			}

			th:not(:first-child),
			td:not(:first-child) {
				text-align: center;
				border-left: 20px solid #fff;
			}

			td:first-child {
				text-align: right;
			}

			td:nth-child(2) {
				background-color: #E4C7E0;
			}

			td:nth-child(3) {
				background-color: #E5E2F1;
			}

			td:nth-child(4) {
				background-color: #FECCE6;
			}

			.bull {
				font-family: $Futura;
				font-size: 1.5rem;
			}

			.expand-block-title {
				font-family: $Arboria;
				padding-bottom: 15px;
				text-transform: uppercase;
				max-width: 80%;
				margin-bottom: 20px !important;
				font-weight: normal;

				span {
					min-width: 0 !important;
					font-size: .8em;
				}

				&:after {
					content: none;
				}
			}
		}

		.expand-block-title {
			margin: 0 auto;

			span {
				display: block;
			}

			&.big {
				text-transform: uppercase;
				@include fs(50);
				font-family: $Arboria;
				border-radius: 30px;
				font-weight: normal;
				width: 33.33%;
				min-width: 300px;
				padding-bottom: 15px;

				span {
					font-size: .5em;
				}

				&:after {
					content: none;
				}
			}
		}

		&.entreprises-premium {
			background-color: transparent;
		}

		.form-control {
			max-width: 330px;
			display: inline-block;
		}

		&-item {
			margin-bottom: 50px;

			.expand-block {
				margin-bottom: 20px;

				&-title {
					border-radius: 3rem;
					min-height: 140px;
				}
			}

			.form-control {
				margin-top: 20px !important;
			}





		}
	}
}

.articles-aside {

	.articles-aside-image {
		margin-bottom: 15px;
		max-height: 450px;
		//height: calc(100vw * .25);
	}
}

.form {
	padding: 30px 5%;
	background-color: #F7F7F7;
	color: #A5A1A1;
}

.video-item {
	position: relative;
	overflow: hidden;
	height: calc(100vw * .28);
	max-height: 530px;

	.icon-video {
		left: 40px;
		top: 50px;
		@include fs(24);
		font-weight: bold;

		img {
			margin-right: 10px;
			vertical-align: middle;
		}
	}

	&-content {
		position: absolute;
		left: 40px;
		bottom: 30px;
		color: #000;
		width: 500px;
		max-width: 85%;
	}

	.articles-title {

		span {
			display: block;

			&:first-child {
				padding-bottom: 15px;
				margin-bottom: 15px;
				border-bottom: 1px solid #707070;
			}

			&:last-child {
				font-family: $Futura;
			}
		}
	}
}

.articles-links {

	a:not(.btn) {
		font-size: 12px;
		margin-right: 10px;
		margin-bottom: 10px;
		font-weight: normal;
		color: $violet;

		&:hover {
			color: #000;
		}

		img {
			vertical-align: middle;
			margin-right: 5px;
		}
	}
}

.actualites {

	&-item {
		height: 100%;
		margin-bottom: 0;
		transition: .3s;

		&:hover {
			border-color: $violet;
		}

		.image-object {
			border-radius: 20px;
			height: 400px;
			margin-bottom: 10px;
		}

		&-date {
			color: $violet;
			@include fs(18);
			font-weight: bold;
			margin-bottom: 10px;
		}

		.title-small {
			font-weight: bold;
			color: #000;
			margin-bottom: 15px;
		}
	}

	// &-video {
	// 	padding-bottom: 0;
	// }

	// .video-item {
	// 	max-height: 395px;

	// 	.articles-title span {
	// 		font-size: .8em;
	// 	}
	// }

	&-feed {

		// 	.title-w-icon {
		// 		color: #0C9BE6;
		// 		margin-bottom: 40px;

		// 		span {
		// 			margin: 0 auto;
		// 		}
		// 	}

		// 	&-logo {
		// 		max-width: 60px;
		// 		margin-bottom: 20px;
		// 	}

		// 	&-date {
		// 		color: #0C9BE6;
		// 		font-weight: bold;
		// 		@include fs(18);
		// 		margin-bottom: 20px;
		// 	}

		// 	&-text {
		// 		@include fs(15);
		// 	}

		// 	&-title {
		// 		font-weight: bold;
		// 		margin-bottom: 20px;
		// 	}

		.image-w-link img {
			width: 100%;
		}
	}

	&-social {

		.header-social a img {
			transition: filter .3s;
		}

		.header-social a:hover img {
			filter: brightness(0) saturate(100%) invert(0%) sepia(3%) saturate(0%) hue-rotate(306deg) brightness(100%) contrast(100%);
		}
	}

	&-article {

		.text-item {
			margin: 30px 0;

			img {
				border-radius: 50px;
				height: calc(100vw * .3);
				max-height: 350px;
				min-height: 250px;
				margin: 10px 0;
			}
		}

		&-links {

			a {
				margin-right: 30px;
				vertical-align: middle;

				img {
					transition: filter .3s;
				}

				&:hover {

					img {
						filter: brightness(0);
					}
				}
			}

		}
	}
}

.entreprises {

	&-featured {
		border: 1px solid #707070;
		transition: box-shadow .3s;

		&-header {
			position: relative;
			background-color: $pink;
			padding: 20px 50px;
			color: #fff;

			.icon {
				margin-right: 10px;
				height: 25px;

				&:first-child,
				&:last-child {
					margin-right: 25px;
				}
			}

			.promo-circle {
				position: absolute;
				width: 55px;
				height: 55px;
				font-size: .9rem;
				right: 30px;
				bottom: -5px;
				background-color: #fff;
				color: $pink;
				font-weight: bold;
				border: 1px solid $pink;
			}
		}

		.entreprises-item-image {
			width: 100%;
			min-height: 420px;
		}

		.entreprises-item-text {
			width: 100%;
			padding: 0;

			.header-social {
				text-align: left;
			}
		}

		&-inner {
			padding: 30px;
		}

		.title-middle {
			margin-bottom: 20px;
			font-weight: bold;
		}

		.articles-text {
			color: #5D7884;
			line-height: 1.8;
			margin-bottom: 0;
		}
	}

	&-premium {
		text-align: center;
		padding: 15px 30px;
		background-color: rgba(150, 62, 139, .1);
		height: 100%;
		color: $violet;

		&-title {
			@include fs(24);
			font-weight: bold;
			color: $l-violet;
			text-transform: uppercase;
			margin-bottom: 20px;

			span {
				display: block;
				color: $pink;
			}
		}

		.title-large {
			font-family: $Arboria;
			color: $violet;
			text-transform: uppercase;

			span {
				display: block;
				font-size: .5em;
				margin-bottom: 15px;
			}
		}

		.btn {
			width: auto;
			min-width: 250px;
		}

		label {
			display: block;
			margin-bottom: 30px;
			@include fs(18);
		}

		.form-control {
			margin-top: 5px;
			text-align: center;
			border-radius: 15px;
			color: $violet;
			@include fs(30);
			font-family: $Copperplate;
			font-weight: normal;
			margin-bottom: 20px;

			.title-large {
				font-family: $Copperplate;
			}
		}

		.prompt {
			font-size: 14px;
			display: block;
			margin-top: 20px;
		}
	}

	&-search-form {
		height: 100%;

		.nice-select {
			float: none;
		}

		.title-small {
			font-weight: normal;
		}

		label {
			display: inline-block;
			color: $violet;
			margin-bottom: 10px;
		}

		.text-center {

			.btn {
				vertical-align: middle;
				width: 100%;
			}
		}
	}

	&-item {
		box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
		font-family: $Futura;
		color: #000;
		font-weight: medium;

		&-content {
			display: flex;
			flex-direction: row;
			height: 100%;
		}

		&-header {
			position: relative;
			background-color: $violet;
			color: #fff;
			padding: 7px 20px;

			.icon {
				height: 25px;
				margin-right: 10px;

				&:first-child,
				&:last-child {
					margin-right: 15px;
				}
			}

			.promo-circle {
				position: absolute;
				right: 15px;
				bottom: -10px;
			}
		}

		&-image {
			position: relative;
			min-height: 100%;
			width: 48%;
			transition: filter .3s;

			&:hover {
				filter: brightness(1.1);
			}
		}

		&-logo {
			position: absolute;
			display: flex;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 90%;
			height: 130px;
			background-color: #fff;
			padding: 10px;

			img {
				margin: auto;
				max-height: 100%;
			}
		}

		&-text {
			width: 52%;
			height: 100%;
			padding: 25px 15px 5px 15px;
			color: #5D7884;

			.title-small {
				margin-bottom: 20px;
				line-height: 1.2;
			}

			.header-social {
				margin-top: auto;
				padding-top: 10px;
				text-align: right;
			}

			.header-social a {
				margin-right: 0;
				width: 22px;
				height: 22px;
				transition: filter .3s;

				&:hover {
					filter: grayscale(1);
				}
			}
		}
	}

	&-single {

		&-properties {

			.btn {
				display: inline-block;
				cursor: inherit;
				margin: 0 15px 15px 0;
				min-width: 15%;

				&:hover {
					color: #fff;
					box-shadow: none;
				}

				&:nth-child(2) {
					opacity: .85;
				}

				&:nth-child(3) {
					opacity: .75;
				}

				&:nth-child(4) {
					opacity: .65;
				}

				&:nth-child(4) {
					opacity: .55;
				}
			}
		}

		&-logo {
			margin-right: auto;
			max-height: 270px;
			padding: 15px;
		}

		.contacts-item {
			position: relative;
			display: flex;
			align-items: center;
			padding-left: 50px;
			min-height: 30px;
			@include fs(22);
			color: #262525;
			font-weight: normal;
			transition: color .3s;

			&:not(:last-child) {
				margin-bottom: 20px;
			}

			&:not(div):hover {
				color: $pink;
			}

			&-icon {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				display: inline-block;
				width: 40px;
				margin-right: 5px;
				vertical-align: middle;
				text-align: center;
			}
		}

		.text-item {
			@include fs(21);
			font-weight: bold;
			text-align: center;
			margin-bottom: auto;

			p:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		.header-social {
			text-align: center;

			a {

				img {
					transition: filter .3s;
				}

				&:hover {

					img {
						filter: brightness(0);
					}
				}
			}
		}

		&-about {

			.text-item {

				img {
					display: block;
					margin: 10px 0;
					height: calc(100vw * .2);
					max-height: 300px;
					min-height: 200px;
				}

				&-bg {
					background-color: #F6F5F5;

					img {
						display: block;
						margin: 0;
					}

					.image-object {
						height: 100%;
					}
				}

				&-text {
					padding: 15px;
				}
			}

			.title-small {
				font-weight: bold;
				margin-bottom: 30px;
				text-transform: uppercase;
				letter-spacing: 2px;

				&.lowercase {
					font-size: inherit;
					letter-spacing: 0;
				}
			}
		}

		&-nav {
			margin-top: 50px;

			.btn {
				margin-bottom: 10px;
			}
		}

		&-views {
			float: right;
			color: $violet;
			font-weight: bold;

			img {
				display: inline-block;
				margin-right: 15px;
				vertical-align: middle;
			}
		}
	}
}

.evenements {

	&-buttons {
		margin-left: auto;

		.btn {
			margin-bottom: 10px;
			margin-left: 10px;
		}
	}

	&-item {
		margin-bottom: 30px;

		&.big {

			img {
				height: 470px;
			}
		}

		&-image {
			display: block;
			width: 100%;
		}

		&-title {
			position: relative;
			@include fs(21);
			margin-top: 15px;
			font-weight: bold;
			color: $violet;
			padding-left: 50px;

			img {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 29px;
			}

			&.color-gray {
				font-size: 1rem;

				.btn {
					float: right;
				}
			}

			&.small {
				@include fs(18);
			}
		}
	}

	.entreprises-premium-title {
		text-transform: none;
		text-align: center;
		margin: 30px;
	}

	.promo-item-small {
		min-height: inherit;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}

		&:first-child {
			height: 57%;
			padding-bottom: 10px;
			max-height: 410px;
		}

		&:last-child {
			height: 43%;
			max-height: 290px;
		}
	}

	.promo-item-big {
		max-height: 690px;
		min-height: inherit;
		height: 100%;

		img {
			display: block;
			height: 100%;
		}
	}

	.promo-item {
		max-height: 580px;
		min-height: inherit;
		height: 100%;

		img {
			display: block;
			height: 100%;
			width: 100%;
		}
	}

	.video-item {
		height: auto;
		max-height: 680px;

		.video-item-content {
			color: #fff;
			bottom: 50%;
			transform: translateY(50%);

			.date {
				font-size: .85em;
				margin-bottom: 50px;
			}

			.big-title {
				font-size: 1.15em;
				margin-bottom: 40px;

				b {
					display: block;
				}
			}
		}

		.btn {
			position: absolute;
			right: 7%;
			bottom: 30px;
		}
	}

	.text-item {

		.title-big {
			font-weight: bold;
			color: $violet;
			margin-bottom: 20px;
			text-transform: uppercase;
		}
	}

	&-speakers {

		b,
		strong {
			color: $violet;
		}
	}
}

.tv {

	&-item {

		&-title {
			display: flex;
			align-items: center;
			margin-top: 10px;
			@include fs(18);

			.btn {
				font-size: 12px;
				margin-left: auto;
			}

			&:not(:last-child) {
				font-weight: bold;
			}
		}
	}

	.border-radius {
		border-radius: 15px;
	}

	.interviews-slider .slick-prev,
	.tv-slider .slick-prev {
		left: -25px;
		top: 40%;
	}

	.interviews-slider .slick-next,
	.tv-slider .slick-next {
		right: -25px;
		top: 40%;
	}

	&-next {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		justify-content: center;

		&-item {
			width: 16.66%;
			min-width: 158px;
			@include fs(21);
			padding: 0 10px 30px 10px;
		}

		&-title {
			height: 125px;
			display: flex;
			padding: 15px;
			align-items: center;
			margin-bottom: 10px;
		}
	}

	.entreprises-single-nav {
		margin: 30px 0 50px;
	}

	&-single {

		&-item {

			.entreprises-single-views {
				float: none;
				color: #fff;
				font-weight: normal;

				&:not(:last-child) {
					margin-right: 50px;
				}

				img {
					height: 40px;
					filter: brightness(10);
				}
			}

		}
	}

	.evenements-buttons {
		display: flex;
		flex-wrap: wrap;
		margin-left: 30px;
	}

	&-slider {
		margin: 0 -10px;

		&-item {
			padding: 0 5px;

		}
	}
}

.membre {

	&-photo {
		display: block;
		text-align: center;
		margin-bottom: 4rem;

		&-title {
			position: relative;
			display: block;
			padding: 0 150px;

			.btn {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.circle {
			position: relative;
			width: 180px;
			height: 180px;
			margin-bottom: 35px;
			margin-top: -80px;
			border: 2px solid transparent;
			cursor: pointer;
			transition: background-color .3s, border-color .3s;
			z-index: 1;

			&:hover {
				border-color: $pink;
			}

			&.image-object {
				margin-left: auto;
				margin-right: auto;
			}
		}

		.title-large {
			font-weight: bold;
		}

		&-empty {
			color: $l-gray;
			@include fs(18);
			margin-bottom: 30px;

			.circle {
				margin-top: 0;
				background-color: $pink;
				margin-bottom: 15px;
				transition: background-color .3s;

				&:hover {
					background-color: #fff;
				}
			}

			img {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				max-width: 50%;
			}

		}
	}

	&-menu {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-left: -5px;
		margin-right: -5px;
		margin-bottom: 6rem;

		li {
			min-width: 14%;
			list-style-type: none;
			padding: 5px;

			&.active {

				.btn {
					background-color: #fff;
				}

				.btn-pink {
					color: $pink;
				}

				.btn-violet {
					color: $l-violet;
				}

				.btn-d-violet {
					color: $violet;
				}
			}
		}

		.btn {
			display: block;
			border-radius: 10px;
			padding: 15px 20px;
		}
	}

	.section-title {
		font-weight: normal;
	}

	.form {
		padding: 5%;

		.btn {
			min-width: 200px;
			border-radius: 6px;
		}

		.link-more {
			font-weight: normal;
			text-decoration: underline;
			color: #000;
		}
	}

	.form-inner {
		max-width: 900px;
		margin: 0 auto;
	}

	&-profil-form {

		.membre-photo .circle {
			background-color: transparent;
			border-color: #A5A1A1;

			img {
				filter: contrast(0);
				transition: filter .3s;
			}

			&:hover {
				background-color: $pink;


				img {
					filter: none;
				}
			}
		}
	}


	&-abonnements {

		&-table {

			.icon {
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
			}

			a:not(.btn) {
				color: #A5A1A1;
			}
		}

		.title-w-border {
			border-color: $pink;
		}

		&-item {
			display: block;
			cursor: pointer;

			.title-big {
				font-family: $Arboria;
				font-weight: normal;
			}

			.btn,
			.form {
				border-radius: 15px;
			}

			.form {
				margin-top: 15px;
				line-height: 2;
				text-align: center;
				color: #000;
				padding: 5px 10px;
			}

			.form-radio {
				display: inline-block;
				padding-left: 0;
				margin-top: 15px;

				span {
					position: relative;
					border-color: $violet  !important;
					left: auto;
					top: auto;
					transform: none;

					&:after {
						background-color: $violet;
					}
				}
			}

			.btn-white-pink {
				max-width: 300px;
				margin: 0 auto 20px auto;
				border-radius: 50px;
			}

			.text-item.small {
				font-size: 1.1rem;
			}

			&.active {
				.btn-white-pink {
					opacity: .5;
				}
			}
		}
	}

	&-emplois {

		&-slider {
			margin-bottom: 50px;

			.offres-advert-item {
				color: #000;
				padding: 20px 10px 10px 20px;
				margin-bottom: 10px;
				border: none;
			}

			.slick-prev,
			.slick-next {
				filter: none;
				top: 40%;

				&:hover {
					filter: brightness(10);
				}
			}

			.slick-prev {
				left: -25px;
			}

			.slick-next {
				right: -25px;
			}

			.slick-list {
				margin-left: -10px;
				margin-right: -10px;
			}

			.slick-slide {
				margin-right: 10px;
				margin-left: 10px;
			}

			.bottom-info .icon {
				margin-right: 10px;
			}

			.icon {
				transition: filter .3s;
			}

			a:hover {

				.icon {
					filter: grayscale(1);
				}
			}
		}

		&-info {
			margin-bottom: 50px;

			.bg-white {
				position: relative;
				padding: 50px 7%;
				color: #000;
				margin-bottom: 15px;
			}

			.form-control {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 5px 30px;
				left: 40px;
				top: 40px;
				width: auto;
				border-radius: 15px;
				font-weight: normal;
				color: $violet;
			}

			.articles-work-title {
				text-align-last: left;
				margin: 0 0 30px !important;
			}

			.col-md-8 {
				padding-left: 7%;
			}

			h4 {
				text-transform: uppercase;
				font-weight: bold;
			}

			.bottom-info {

				.icon {
					margin-right: 10px;
				}
			}
		}

		&-logo {
			display: block;
			margin: 0 auto 50px auto;
			max-height: 250px;

		}

	}
}

.livre {

	&-image-big {
		object-fit: contain;
	}

	&-product {

		&-content {
			display: flex;
			flex-direction: row;
		}

		&-image {
			text-align: center;

			.icon {
				margin-top: 10px;
				transition: filter .3s;
			}

			&:hover {

				.icon {
					filter: brightness(0);
				}
			}
		}

		&-info {

			.title-large {
				font-weight: bold;
				margin-bottom: 10px;

				.title-middle {
					display: block;
				}
			}

			.text-item {

				p {
					margin-bottom: 0 !important;
				}
			}
		}

		&-buy {
			padding: 30px;
			color: #000;

			.title-middle {
				font-weight: bold;
			}

			.icon {
				width: 30px;
				height: 30px;
				margin-right: 15px;
			}

			.btn {
				width: 100%;
				border-radius: 15px;
				margin-top: 5px;
			}

			.text-item {
				margin-bottom: 0;

				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
	}

	&-slider {

		&-wrapper {
			padding: 0 17%;

			.title-large {
				color: $pink;
				margin-bottom: 30px;
				text-align: center;
			}
		}

		.slick-next,
		.slick-prev {
			filter: none;
			top: 45%;

			&:hover {
				filter: brightness(0);
			}
		}


		.slick-next {
			right: -25px;
		}

		.slick-prev {
			left: -25px;
		}
	}
}

.charte {

	&-about {

		.section-title {
			text-align: left;
		}

		&-photo {
			text-align: center;
			max-width: 100%;
			width: 300px;
			margin: 0 auto;

			.title-small {
				margin-top: 10px;
				font-weight: bold;
			}
		}

		&-text {
			@include fs(30);
			font-style: italic;
			line-height: 2;
		}

		&-image {
			display: block;
			width: 55%;
			margin: 15px auto;
		}

		&-item {
			line-height: 1.6;
			@include fs(30);
			margin-bottom: 50px;

			h3 {
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
}

.text {

	&-page {
		border-radius: 50px;
		background-color: #fff;
		padding: 50px;

		.title-large {
			text-align: center;
			color: #A5A1A1;
			margin-bottom: 50px;
		}

		.text-item {
			font-size: 14px;
			columns: 2;
			column-gap: 40px;

		}

		h3,
		h4,
		h5,
		h6 {
			font-weight: bold;
			margin-bottom: 30px;
		}

		h3 {
			@include fs(18);
		}
	}
}

.forfaits {

	.title-large {
		text-align: center;
		color: $pink;
		margin-bottom: 50px;
	}

	.circle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 35px;
		height: 35px;
		background-color: $pink;
		color: #fff;
		@include fs(24);
		font-weight: bold;
		line-height: 1;
		margin: 0 auto 10px;
	}

	&-subscribe {
		overflow: visible;

		.form-control {
			font-weight: normal;

			&::placeholder {
				font-weight: normal;
			}
		}

		.form-checkbox {
			font-style: normal;
			display: inline-block;
			text-align: left;

			span {
				top: 3px;
			}
		}

		.form-radio {
			font-weight: normal;

			img {
				max-height: 55px;
			}
		}

		.nice-select {
			color: #A5A1A1;
		}

		&-item {
			max-width: 900px;
			margin: 0 auto;
		}

		&-price {
			background-color: $l-violet;
			color: #fff;
		}

		.slick-prev,
		.slick-next {
			top: 50px;
			filter: none;

			&:hover {
				filter: grayscale(1);
			}
		}
	}
}

.architectes {

	&-gallery {

		&-part {
			display: grid;
			gap: 15px;
			grid-template-columns: 1fr 1fr 1fr;
			margin-bottom: 15px;
		}
	}

	&-item {
		position: relative;
		height: 320px;

		&.big {
			height: calc(320px * 2 + 15px);
		}

		&:nth-child(4) {
			grid-column: 1/3;
			grid-row: 2/4;
		}

		&:nth-child(11) {
			grid-column: 2/4;
			grid-row: 5/7;
		}

		&:nth-child(13) {
			grid-column: 1/3;
		}

		img {
			display: block;
		}

		&-content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			opacity: 0;
			visibility: hidden;
			background-color: rgba(49, 49, 49, .7);
			color: #fff;
			text-align: center;
			font-weight: bold;
			padding: 10px;
			transition: opacity .3s, visibility .3s;


			p {
				margin-bottom: 0 !important;
			}

			.icon {
				margin-bottom: 15px;
			}
		}

		&:hover {

			.architectes-item-content {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&-bottom {
		margin-bottom: 50px;

		img {
			display: block;
			width: 100%;
			padding: 15px 0;
			background-color: #76A346;
		}

	}
}

.chiffres {

	&-item {
		position: relative;
		height: 480px;

		.image-object {
			filter: brightness(.7);
		}

		&-content {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
			padding: 50px 15px;
			color: #fff;
			text-align: center;
			font-family: $Geometos;
			@include fs(160);

			small {
				font-size: .55em;
			}

			.title-middle {
				font-weight: bold;
				font-family: $AdorHR;
			}
		}

		&-big {}
	}
}

.sitemap {

	&-list {
		margin-bottom: 50px;
	}

	&-item {
		border-radius: 15px;
		overflow: hidden;
		background-color: rgba(150, 62, 139, .4);
		list-style-type: none;

		li {

			&:first-child {
				border-bottom: 2px solid #fff;

				a {
					font-size: 1.125em;
					color: #fff;
					font-weight: bold;
					padding-top: 15px;

					&:hover {
						color: #000;
					}
				}
			}

			&:last-child {

				a {
					padding-bottom: 15px;
				}
			}

			&:not(:last-child):not(:first-child) {
				border-bottom: .5px solid #fff;
			}

		}

		a {
			display: block;
			color: #000;
			padding: 5px 20px;
			transition: .3s;

			&:hover {
				background-color: #EFEDED;
				text-shadow: 0 0 1px #000;
			}
		}
	}
}

.network {

	.text-item {
		@include fs(24);
	}

	&-content {
		margin-bottom: 50px;

		&-title {
			@include fs(24);
			color: $violet;
			text-align: center;
			padding: 15px 0;
			margin-bottom: 0;
		}

		&-image {
			position: relative;

			.text-item {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				padding: 40px;
				background-color: $pink;
				color: #fff;
				margin-bottom: 0;
			}
		}

		&-text {
			background-color: #EFEDED;
			border-radius: 15px;
			padding: 40px 30px;
			margin-bottom: 0;
		}
	}

	&-product {
		padding: 40px 50px;
		background: linear-gradient(to bottom, #963E8B, #4B1F46);
		color: #fff;

		.title-large {
			margin-bottom: 20px;
		}

		.v-align-center {
			height: 100%;
		}

		&-wrapper {
			margin-bottom: 50px;

			.title-small {
				color: $violet;
				margin-bottom: 50px;
				font-weight: bold;
			}
		}
	}
}

.webinaire {

	&-partners {
		text-align: center;

		img {
			max-height: 100px;
			margin: 0 5px;
		}

		.text-item {
			margin-top: 20px;
		}
	}
}