/* fonts */

@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura';
    src: url('../fonts/Futura-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdorHairline';
    src: url('../fonts/AdorHairline-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdorHairline';
    src: url('../fonts/AdorHairline-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdorHairline';
    src: url('../fonts/AdorHairline-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdorHairline';
    src: url('../fonts/AdorHairline-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'AdorHairline';
    src: url('../fonts/AdorHairline-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AdorHairline';
    src: url('../fonts/AdorHairline-Medium-Italic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Arboria-Book';
    src: url('../fonts/Arboria-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Copperplate';
    src: url('../fonts/Copperplate.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometos';
    src: url('../fonts/Geometos.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$Copperplate: "Copperplate", Arial, sans-serif;
$Arboria: "Arboria", Arial, sans-serif;
$AdorHR: "AdorHairline", Arial, sans-serif;
$Futura: "Futura", Arial, sans-serif;
$Geometos: "Geometos", Arial, sans-serif;